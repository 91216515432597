/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Directive, Input } from '@angular/core';
import { MatOption } from '@angular/material/core';

@Directive({
  selector: '[matOptionViewValue]',
  standalone: true,
})
export class MatOptionViewValueDirective {
  constructor(option: MatOption) {
    Object.defineProperty(option, 'viewValue', { get: () => this.matOptionViewValue });
  }
  @Input()
  matOptionViewValue!: string;
}
