/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { clusterList, currentCluster, currentClusterChange } from '@app/core/ngrx';
import { AppState } from '@app/core/types';
import { fadeAnim } from '@common/animations';
import { Store } from '@ngrx/store';
import { identity } from 'lodash-es';
import { map } from 'rxjs/operators';
import { ClusterStatusComponent } from '../../../common/components/cluster-status/cluster-status.component';
import { DetectEllipsisDirective } from '../../../common/directives/detect-ellipsis.directive';
import { MatOptionViewValueDirective } from '../matOptionViewValue.directive';

@Component({
  selector: 'current-cluster-select',
  templateUrl: './current-cluster-select.component.html',
  styleUrls: ['./current-cluster-select.component.scss'],
  animations: [fadeAnim],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatSelectModule,
    NgClass,
    ClusterStatusComponent,
    MatTooltipModule,
    MatOptionModule,
    MatOptionViewValueDirective,
    MatIconModule,
    DetectEllipsisDirective,
    AsyncPipe,
  ],
})
export class CurrentClusterSelectComponent {
  constructor(private store: Store<AppState>) {}
  clusters$ = this.store.select(clusterList).pipe(
    map((clusters) => [...clusters].sort((a, b) => a.tag.localeCompare(b.tag))),
    takeUntilDestroyed(),
  );
  currentClusterID$ = this.store.select(currentCluster).pipe(takeUntilDestroyed());
  hasData$ = this.clusters$.pipe(map((a) => !!a && a.length));
  clustersCount$ = this.clusters$.pipe(map((a) => a.length));

  onChange(change: MatSelectChange) {
    this.store.dispatch(currentClusterChange({ id: change.value }));
  }

  // Silences the NG0956 warning (https://angular.dev/errors/NG0956)
  public identity = identity;
}
