/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../types';

export const completedTutorials = createFeatureSelector<AppState['completedTutorials']>('completedTutorials');
export const isTutoralComplete = (id: string) =>
  createSelector(completedTutorials, (completed) => completed.includes(id));
