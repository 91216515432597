/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { NotificationsState } from '@app/core/notification/types';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEmpty, some } from 'lodash-es';

export const notificationsSelector = createFeatureSelector<NotificationsState['notifications']>('notifications');

export const notificationsList = createSelector(notificationsSelector, (n) => n.list);
export const notificationsShowOnlyUnread = createSelector(notificationsSelector, (n) => n.showOnlyUnread);
export const notificationsListFiltered = createSelector(notificationsSelector, (n) =>
  n.showOnlyUnread ? n.list.filter((it) => !it.read) : n.list,
);

export const hasUnreadNotifications = createSelector(notificationsList, (notifications) =>
  some(notifications, { read: false }),
);

export const hasNewNotifications = createSelector(notificationsSelector, (n) => !isEmpty(n.new));

export const newNotificationsList = createSelector(notificationsSelector, (n) => {
  return n.list.filter((it) => n.new.includes(it.id));
});
