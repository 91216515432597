/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */
import { Pipe, PipeTransform } from '@angular/core';
import { plural } from '@common/utils/plural';

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = DAY * 30;
const YEAR = DAY * 365;

type Unit = 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year';
type Suffix = 'ago' | 'from now';

const Units: Record<Unit, number> = {
  year: YEAR,
  month: MONTH,
  week: WEEK,
  day: DAY,
  hour: HOUR,
  minute: MINUTE,
  second: 1,
};

@Pipe({
  name: 'dateAgo',
  pure: false,
  standalone: true,
})
export class DateAgoPipe implements PipeTransform {
  transform(date?: number, minUnit: Unit = 'second', binder: boolean = true, suffix: Suffix = 'ago'): string {
    if (date) {
      const seconds = Math.round((Date.now() - date) / 1000);
      if (seconds < Units[minUnit]) return `less than ${minUnit} ${suffix}`;

      const modulo = this.valueFormatter(seconds);
      if (modulo) {
        const mainTime = `${modulo.value} ${plural(modulo.unit, modulo.value)}`;
        const remainder = this.moduloFormatter(modulo.unit, seconds, minUnit);
        if (remainder && remainder.value > 0) {
          const secondaryTime = `${remainder.value} ${plural(remainder.unit, remainder.value)}`;
          const binderValue = binder ? ' and ' : ' ';
          return `${mainTime}${binderValue}${secondaryTime} ${suffix}`;
        } else {
          return `${mainTime} ${suffix}`;
        }
      }
    }
    return 'a moment ago';
  }

  valueFormatter = (seconds: number) => {
    for (const [key, interval] of Object.entries(Units)) {
      const value = Math.floor(seconds / interval);
      if (value > 0) return { value, unit: key as Unit };
    }
  };

  moduloFormatter = (unit: Unit, seconds: number, minUnit: Unit) => {
    const remainder = seconds % Units[unit];
    if (remainder < Units[minUnit]) return null;
    return this.valueFormatter(remainder);
  };
}
