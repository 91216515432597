/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, inject, input, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlContainer, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ignite3xCredentialsValidator } from '@app/common/validators/ignite-3x-credentials';
import { userOpenIdProviderName } from '@app/core/ngrx/user.selectors';
import { AppState } from '@app/core/types';
import { shareReplayLastWithRefCount } from '@common/rxjs-operators/share-replay-last-with-ref-count';
import { WrapReactiveFormControls } from '@common/types/form';
import { Store } from '@ngrx/store';
import { Cluster2xConnectionToken } from '@shared/types/cluster';
import { merge, Observable, of } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { AutofocusDirective } from '../../../directives/auto-focus.directive';
import { OpenIdProviderNamePipe } from '../../../pipes/open-id-provider-name.pipe';
import { GmcErrorsModule } from '../../gmc-errors/gmc-errors.module';
import { PasswordVisibilityComponent } from '../../password-visibility/password-visibility.component';

export type FormClusterSigninFormData = {
  connectionToken: Cluster2xConnectionToken | undefined;
  username: string;
  password: string;
  useAccessToken: boolean;
};

@Component({
  selector: 'form-cluster-signin',
  templateUrl: './form-cluster-signin.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    AutofocusDirective,
    GmcErrorsModule,
    PasswordVisibilityComponent,
    MatSlideToggleModule,
    AsyncPipe,
    OpenIdProviderNamePipe,
  ],
})
export class FormClusterSigninComponent implements OnInit {
  constructor(
    private store: Store<AppState>,
    private controlContainer: ControlContainer,
  ) {}
  private destroyRef = inject(DestroyRef);
  isSecure$: Observable<boolean> = of(true);
  formGroup?: FormGroup<WrapReactiveFormControls<FormClusterSigninFormData>>;
  userOpenIdProvider$ = this.store.select(userOpenIdProviderName);

  @Input() lockConnectionTokenTo?: Cluster2xConnectionToken = '';
  @Input() showConnectionTokenInput? = true;
  @Input() showDeferToProviderToggle = false;
  public disallowColons = input<boolean>();

  ngOnInit() {
    this.formGroup = this.controlContainer.control as FormGroup<WrapReactiveFormControls<FormClusterSigninFormData>>;
    this.formGroup.addControl('connectionToken', new FormControl());
    this.formGroup.controls.connectionToken.setValue(this.lockConnectionTokenTo);
    this.formGroup.controls.connectionToken.setValidators([Validators.required]);
    if (!!this.lockConnectionTokenTo) {
      this.formGroup.controls.connectionToken.disable();
    }

    this.formGroup.addControl('username', new FormControl());
    this.formGroup.addControl('password', new FormControl());
    this.formGroup.addControl('useAccessToken', new FormControl());
    this.formGroup.patchValue({ useAccessToken: false });

    this.formGroup.controls.useAccessToken.valueChanges
      .pipe(
        tap((value: boolean) => {
          if (value) {
            this.formGroup?.controls.username.reset();
            this.formGroup?.controls.username.disable();
            this.formGroup?.controls.password.reset();
            this.formGroup?.controls.password.disable();
          } else {
            this.formGroup?.controls.username.enable();
            this.formGroup?.controls.password.enable();
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
    const connectionToken$: Observable<string | undefined> = merge(
      this.formGroup.controls.connectionToken.statusChanges
        .pipe(withLatestFrom(this.formGroup.controls.connectionToken.valueChanges))
        .pipe(
          filter(([status]) => status === 'VALID'),
          map(([status, value]) => value),
        ),
      of(this.formGroup.getRawValue().connectionToken).pipe(filter((v) => !!v)),
    );

    this.isSecure$ = connectionToken$.pipe(
      map(Boolean),
      tap(() => {
        // setTimout fixes "Expression has changed after it was checked" error
        // when form is in dialog
        setTimeout(() => {
          if (!this.formGroup) return;
          this.formGroup.controls.username.setValidators([Validators.required]);
          this.formGroup.controls.password.setValidators([Validators.required]);
          if (this.disallowColons()) {
            this.formGroup.controls.username.addValidators(ignite3xCredentialsValidator);
            this.formGroup.controls.password.addValidators(ignite3xCredentialsValidator);
          }
          this.formGroup.controls.username.updateValueAndValidity();
          this.formGroup.controls.password.updateValueAndValidity();
        });
      }),
      shareReplayLastWithRefCount,
    );
  }
}
