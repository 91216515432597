/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { login, logoutSuccess } from '@app/core/ngrx';
import {
  dismissAllNotifications,
  notifications,
  readAllNotifications,
  readAllNotificationsErr,
  readAllNotificationsOK,
  toggleNotificationStatus,
  toggleNotificationStatusErr,
  toggleNotificationStatusOk,
} from '@app/core/notification/ngrx/notifications.actions';
import { notificationsList } from '@app/core/notification/ngrx/notifications.selectors';
import { NotificationsApiService } from '@app/core/notification/services';
import { ToastService } from '@app/core/notification/services/notification-toast.service';
import { NotificationsState } from '@app/core/notification/types';
import { extractError } from '@common/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NotificationId } from '@shared/types/notification';
import { isEmpty } from 'lodash-es';
import { of } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class NotificationsEffects {
  constructor(
    private actions$: Actions,
    private notificationsApi: NotificationsApiService,
    private store: Store<NotificationsState>,
    private toastService: ToastService,
  ) {}

  notifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      switchMap(() =>
        this.notificationsApi.subscribeToNotifications().pipe(
          withLatestFrom(this.store.select(notificationsList)),
          map(([incoming, existing], index) => {
            let newIds: NotificationId[] = [];

            // get all notifications on the very first emission
            if (index === 0) return notifications({ data: incoming, new: newIds });

            // all the next emissions contain only updates
            if (!isEmpty(existing)) {
              const oldIds = existing.map((it) => it.id);
              newIds = incoming.filter((it) => !oldIds.includes(it.id)).map((it) => it.id);
            } else {
              newIds = incoming.map((it) => it.id);
            }
            return notifications({ data: incoming, new: newIds });
          }),
          takeUntil(this.actions$.pipe(ofType(logoutSuccess))),
        ),
      ),
    ),
  );

  toggleNotificationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toggleNotificationStatus),
      switchMap(({ id, read }) =>
        this.notificationsApi.toggleNotificationStatus(id, read).pipe(
          map(() => toggleNotificationStatusOk()),
          catchError((err) =>
            of(
              toggleNotificationStatusErr({
                error: extractError(err, 'Failed to toggle notification status'),
                id,
                read: !read,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  readAllNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(readAllNotifications),
      switchMap(() =>
        this.notificationsApi.readAllNotifications().pipe(
          map(() => readAllNotificationsOK()),
          catchError((err) =>
            of(
              readAllNotificationsErr({
                error: extractError(err, 'Failed to mark all notifications as read'),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  dismissAllNotifications$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(dismissAllNotifications),
        tap(() => this.toastService.hide()),
      ),
    { dispatch: false },
  );
}
