/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { isTruthy } from '@app/common/utils';
import { initUser, isRequestInFlight, licenseStatus, targetSelector } from '@app/core/ngrx';
import { userIsAdmin } from '@app/core/ngrx/user.selectors';
import { AppState } from '@app/core/types';
import { Store } from '@ngrx/store';
import { GGCCLicenseType } from '@shared/types/license';
import { Observable, combineLatest, filter, map, of, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NoLicenseGuard {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(targetSelector).pipe(
      filter(isTruthy),
      switchMap((target) => {
        if (target === 'hosted') return of(true);

        return combineLatest([
          this.store.select(isRequestInFlight(initUser)),
          this.store.select(licenseStatus),
          this.store.select(userIsAdmin),
        ]).pipe(
          filter(([isUserLoading, licenseStatus]) => !isUserLoading && !!licenseStatus),
          map(([, licenseStatus, isAdmin]) => {
            const hasNoLicense = licenseStatus?.licenseType === GGCCLicenseType.noLicense;
            return hasNoLicense
              ? isAdmin
                ? this.router.parseUrl('admin/license')
                : this.router.parseUrl('no-license')
              : true;
          }),
        );
      }),
    );
  }
}
