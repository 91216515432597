<!--
   Copyright (C) GridGain Systems. All Rights Reserved.
   _________        _____ __________________        _____
   __  ____/___________(_)______  /__  ____/______ ____(_)_______
   _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
   / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
   \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
-->
<div
  class="mat-mdc-dialog-container"
  [@toastAnimation]="{
    value: animationState,
    params: config.animation,
  }"
  (@toastAnimation.done)="onAnimationFinished($event)"
>
  <div mat-dialog-content>
    <notification-message
      [notification]="notification"
      [showActions]="true"
      (closeNotification)="onClose()"
    ></notification-message>
  </div>
</div>
