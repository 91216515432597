<!--
   Copyright (C) GridGain Systems. All Rights Reserved.
   _________        _____ __________________        _____
   __  ____/___________(_)______  /__  ____/______ ____(_)_______
   _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
   / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
   \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
-->

<ng-template #versionInfo>
  <div *showForTarget="'on-premise'" class="version-info" gta="footer_project_version">
    <span matTooltip="Product version" gta="footer_version_product">{{ buildInfo.productVersion }}</span>
    @if (buildInfo.branch) {
      <span matTooltip="Branch" gta="footer_version_branch">{{ buildInfo.branch }}</span>
    }
    @if (buildInfo.build) {
      <span matTooltip="Build" gta="footer_version_build">{{ buildInfo.build }}</span>
    }
  </div>
</ng-template>
@if (!wideMode) {
  <ng-container *ngTemplateOutlet="versionInfo"></ng-container>
  @if (isDarkTheme$ | async) {
    <img src="assets/images/ignite-badge-official-dark.svg" alt="" class="powered-by-ignite" />
  }
  @if (!(isDarkTheme$ | async)) {
    <img src="assets/images/ignite-badge-official.svg" alt="" class="powered-by-ignite" />
  }
} @else {
  <p>
    <span gta="footer_project_name"><project-name></project-name></span>
    <span><ng-container *ngTemplateOutlet="versionInfo"></ng-container></span>
  </p>
  <p>
    <span><a href="//www.gridgain.com" target="_blank">www.gridgain.com</a></span>
    <span><a routerLink="terms" target="_blank">Terms and Conditions</a></span>
  </p>
  <p>Copyright © {{ year }} GridGain Systems, Inc. All Rights Reserved.</p>
  <p class="legal"
    >Apache, Apache Ignite, the Apache feather and the Apache Ignite logo are trademarks of The Apache Software
    Foundation.</p
  >
  <div class="bottom">
    @if (isDarkTheme$ | async) {
      <img src="assets/images/ignite-badge-official-dark.svg" alt="" class="powered-by-ignite" />
    }
    @if (!(isDarkTheme$ | async)) {
      <img src="assets/images/ignite-badge-official.svg" alt="" class="powered-by-ignite" />
    }
    @if (wideMode) {
      <a [routerLink]="['.', { outlets: { dialog: ['error-log'] } }]" gta="footer__error-log-link">View error log</a>
    }
  </div>
}
