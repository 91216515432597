<!-- Copyright (C) GridGain Systems. All Rights Reserved.
   _________        _____ __________________        _____
   __  ____/___________(_)______  /__  ____/______ ____(_)_______
   _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
   / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
   \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/ -->

@if (banners$ | async; as banners) {
  <div class="wrapper">
    @if (banners.length > 1) {
      <mat-expansion-panel class="gmc-banners-panel">
        <mat-expansion-panel-header>
          <mat-icon class="counter-icon">{{ getFilterIcon(banners.length) }}</mat-icon>
          <ng-container *ngTemplateOutlet="singleBannerTemplate"></ng-container>
        </mat-expansion-panel-header>
        @for (banner of banners.slice(1); track identity(banner)) {
          <ng-container *ngTemplateOutlet="bannerTemplate; context: { $implicit: banner }"></ng-container>
        }
      </mat-expansion-panel>
    } @else {
      <ng-container *ngTemplateOutlet="bannerTemplate; context: { $implicit: banners[0] }"></ng-container>
    }

    <ng-template #singleBannerTemplate>
      <ng-container *ngTemplateOutlet="bannerTemplate; context: { $implicit: banners[0] }"></ng-container>
    </ng-template>
  </div>
}

<ng-template #bannerTemplate let-banner>
  @switch (banner) {
    @case ('nebula-cluster-limited') {
      <mat-toolbar gta="banner__nebula-cluster-limited">
        <span class="banner_text"
          >Monitoring is limited due the excessive load. Some data is not shown. Consider collecting less metrics,
          traces and compute info.</span
        >
        <a
          mat-button
          color="primary"
          href="https://www.gridgain.com/docs/control-center/latest/faq#why-does-my-cluster-show-the-limited-cluster-banner"
          target="_blank"
          >Learn more</a
        >
      </mat-toolbar>
    }
    @case ('nebula-trial-period') {
      <mat-toolbar gta="banner__nebula-trial-period">
        <!-- prettier-ignore --><span class="banner_text" gta="banner__nebula-trial-period-text"
          >You have @if (availableCredit$ | async; as availableCredit) {{{ availableCredit | map: formatPrice
          }}} left of your trial credits. Based on your usage so far, this amount will only last to
          {{ nebulaTrialBalanceExhaustionTime$ | async | date: 'mediumDate' }}.</span>
        <a
          mat-button
          color="primary"
          (click)="closePanel($event)"
          [routerLink]="['billing', 'update-card']"
          gta="banner__nebula-trial-period-button"
          >Upgrade</a
        >
      </mat-toolbar>
    }

    @case ('nebula-trial-expired') {
      <mat-toolbar class="limit-banner" gta="banner__nebula-trial-expired">
        <span class="banner_text" gta="banner__nebula-trial-expired-text">
          Trial period has expired. Provide payment details before
          {{ nebulaUnpaidTerminationTime$ | async | date: 'mediumDate' }} to use the product.
        </span>
        <a
          mat-button
          (click)="closePanel($event)"
          [routerLink]="['billing', 'update-card']"
          gta="banner__nebula-trial-expired-button"
          >Enter details</a
        >
      </mat-toolbar>
    }

    @case ('nebula-subscription-unpaid') {
      <mat-toolbar class="limit-banner" gta="banner__nebula-subscription-unpaid">
        <span class="banner_text" gta="banner__nebula-subscription-unpaid-text">
          Payment failed. Please retry your payment before
          {{ nebulaUnpaidTerminationTime$ | async | date: 'mediumDate' }} to use the product.
        </span>
        <a
          mat-button
          (click)="closePanel($event)"
          [routerLink]="['billing', { plantype: 'nebula' }]"
          gta="banner__nebula-subscription-unpaid-button"
          >Go to Billing</a
        >
      </mat-toolbar>
    }

    @case ('on-premise-license-warning') {
      <mat-toolbar gta="ggcc_banner" class="license-banner">
        <span gta="banner_text">{{ licenseError$ | async }}</span>
        @if (userIsAdmin$ | async) {
          <a
            mat-flat-button
            (click)="closePanel($event)"
            routerLink="/admin/license"
            gta="banner__on-premise-license-warning-button"
            >Manage license</a
          >
        }
      </mat-toolbar>
    }

    @case ('inactive-cluster') {
      <mat-toolbar gta="ggcc_banner" class="inactive-cluster-banner">
        <span gta="banner_text">The current cluster is inactive.</span>
        @if (isActivateClusterButtonVisible$ | async) {
          <a
            mat-flat-button
            (click)="closePanel($event)"
            [routerLink]="myClusterLink$ | async"
            gta="banner__inactive-cluster-button"
            >Activate</a
          >
        }
      </mat-toolbar>
    }

    @case ('metrics-disabled') {
      <mat-toolbar class="limit-banner" gta="banner__metrics-disabled"
        ><span class="banner_text"
          >Due to excess load, metric collection for this cluster was paused by the support team</span
        >
        <a
          gta="banner__button-contact-support"
          [routerLink]="['/', { outlets: { dialog: ['contact-form'] } }]"
          mat-button
          >Contact support</a
        >
      </mat-toolbar>
    }

    @case ('on-premise-license-with-no-clusters') {
      <mat-toolbar class="no-clusters-banner" gta="banner__license-with-no-clusters">
        <span gta="banner_text">{{ licenseWithNoClustersMessage$ | async }}</span>
        <a
          mat-raised-button
          (click)="closePanel($event)"
          [routerLink]="['/', { outlets: { dialog: ['attach'] } }]"
          gta="banner__on-premise-license-with-no-clusters-button"
          >Attach cluster</a
        >
      </mat-toolbar>
    }
  }
</ng-template>
