/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { NotificationToastContainerRef } from '@app/core/notification/notification-toast-container/notification-toast-container-ref';
import { NotificationToastContainerComponent } from '@app/core/notification/notification-toast-container/notification-toast-container.component';
import { Notification } from '@shared/types/notification';

type ToastContainerPosition = {
  top: number;
  right: number;
};

export class NotificationToastData {
  notification?: Notification;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  overlayRef?: OverlayRef;
  containerRef?: NotificationToastContainerRef;

  toastContainerPositionConfig: ToastContainerPosition = {
    top: 80,
    right: 20,
  };

  constructor(
    private overlay: Overlay,
    private injector: Injector,
  ) {}

  show() {
    if (!this.overlayRef || (!!this.containerRef && !this.containerRef?.isVisible())) {
      this.overlayRef = this.overlay.create({
        positionStrategy: this.getPositionStrategy(),
      });
      this.containerRef = new NotificationToastContainerRef(this.overlayRef);
      const injector = this.createInjector(this.containerRef);
      const containerPortal = new ComponentPortal(NotificationToastContainerComponent, null, injector);
      this.overlayRef.attach(containerPortal);
    }
  }

  hide() {
    if (this.containerRef?.isVisible()) {
      this.containerRef?.close();
    }
  }

  private getPositionStrategy() {
    return this.overlay
      .position()
      .global()
      .top(`${this.toastContainerPositionConfig.top}px`)
      .right(`${this.toastContainerPositionConfig.right}px`);
  }

  private createInjector(containerRef: NotificationToastContainerRef) {
    return Injector.create({
      parent: this.injector,
      providers: [{ provide: NotificationToastContainerRef, useValue: containerRef }],
    });
  }
}
