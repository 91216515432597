/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { NgModule } from '@angular/core';
import { NotificationsEffects, notificationsLocalStorageSyncReducer } from '@app/core/notification/ngrx';
import { NOTIFICATIONS_REDUCERS, notificationsReducers } from '@app/core/notification/ngrx/notifications.reducers';
import { NotificationButtonComponent } from '@app/core/notification/notification-button/notification-button.component';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    StoreModule.forFeature('notifications', NOTIFICATIONS_REDUCERS, {
      metaReducers: [notificationsLocalStorageSyncReducer],
    }),
    EffectsModule.forFeature([NotificationsEffects]),
    NotificationButtonComponent,
  ],
  exports: [NotificationButtonComponent],
  providers: [{ provide: NOTIFICATIONS_REDUCERS, useValue: notificationsReducers }],
})
export class NotificationModule {}
