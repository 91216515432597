<!--
   Copyright (C) GridGain Systems. All Rights Reserved.
   _________        _____ __________________        _____
   __  ____/___________(_)______  /__  ____/______ ____(_)_______
   _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
   / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
   \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
-->

@if (hasData$ | async) {
  <mat-select
    [value]="currentClusterID$ | async"
    [ngClass]="{ single: (clustersCount$ | async) === 1 }"
    (selectionChange)="onChange($event)"
    placeholder="Choose cluster"
    [disabled]="(clustersCount$ | async)! <= 1"
    gta="current-cluster-selector"
    panelClass="mat-select-panel-max-width-285"
    @fadeAnim
    #select
    panelWidth
  >
    <mat-select-trigger>
      <cluster-status class="inline-status" [clusterId]="(currentClusterID$ | async)!" onlyIcon></cluster-status>
      <span matTooltip="Current cluster">{{ select.triggerValue }}</span>
    </mat-select-trigger>
    @for (cluster of clusters$ | async; track identity(cluster)) {
      <mat-option [value]="cluster.id" [matOptionViewValue]="cluster.tag">
        <mat-icon style="display: contents">
          <cluster-status class="inline-status" [clusterId]="cluster.id" onlyIcon></cluster-status>
        </mat-icon>
        <span matTooltip detectEllipsis>{{ cluster.tag }}</span>
      </mat-option>
    }
  </mat-select>
}
