/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { Confirm } from '@app/common/modules/gmc-confirm/confirm.service';
import {
  removeClusterFromTeam,
  removeClusterFromTeamErr,
  removeClusterFromTeamOK,
  shareCluster,
  shareClusterErr,
  shareClusterOK,
  shareClusterValidationErr,
  stopShareWithClusterIntent,
  teamList,
} from '@app/core/ngrx/actions/teams.actions';
import { clusterById, clusterList, currentClusterInfo, team, teams } from '@app/core/ngrx/app.selectors';
import { Clusters } from '@app/core/services';
import { TeamsApiService } from '@app/core/services/teams-api.service';
import { ValidationsApiService } from '@app/core/services/validations-api';
import { AppState } from '@app/core/types';
import { chooseCurrentCluster, clusterName } from '@app/domain/cluster';
import { clusterTeams, getTeamById } from '@app/domain/teams';
import { inviteUsersValidationErr, kickFromTeam, kickFromTeamErr, kickFromTeamOk } from '@app/teams/ngrx/team.actions';
import { handleLoading } from '@common/rxjs-operators';
import { extractError } from '@common/utils';
import { interceptActionTaskErrors } from '@common/utils/task-interceptor';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TeamType } from '@shared/types/team';
import { outdent } from 'outdent';
import { EMPTY, combineLatest, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { login, logoutSuccess } from './actions';

@Injectable()
export class TeamsEffects {
  constructor(
    private actions$: Actions,
    private clustersApi: Clusters,
    private teamsApi: TeamsApiService,
    private validationsApi: ValidationsApiService,
    private confirm: Confirm,
    private store: Store<AppState>,
  ) {}

  loading$ = createEffect(() =>
    this.actions$.pipe(
      handleLoading([
        { start: shareCluster, end: [shareClusterOK, shareClusterErr] },
        { start: removeClusterFromTeam, end: [removeClusterFromTeamOK, removeClusterFromTeamErr] },
      ]),
    ),
  );

  teams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      switchMap(() =>
        this.teamsApi.subscribeToTeams().pipe(
          map((data) => teamList({ data })),
          takeUntil(this.actions$.pipe(ofType(logoutSuccess))),
        ),
      ),
    ),
  );

  notifyAboutInviteValidationError$ = createEffect(() =>
    interceptActionTaskErrors(this.validationsApi, 'validateInviteData').pipe(
      map((err) =>
        inviteUsersValidationErr({
          error: extractError(err, 'Failed to validate user'),
        }),
      ),
    ),
  );

  notifyAboutShareValidationError$ = createEffect(() =>
    interceptActionTaskErrors(this.validationsApi, 'validateShareData').pipe(
      map((err) =>
        shareClusterValidationErr({
          error: extractError(err, 'Failed to validate value'),
        }),
      ),
    ),
  );

  kickFromTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(kickFromTeam),
      switchMap((action) =>
        this.teamsApi.removeTeamMemberFromTeam(action.teamId, action.email).pipe(
          map(() =>
            kickFromTeamOk({
              teamId: action.teamId,
              email: action.email,
              notification: 'User removed from the team',
            }),
          ),
          catchError((err) =>
            of(
              kickFromTeamErr({
                error: extractError(err, 'Failed to remove user from the team'),
                teamId: action.teamId,
                email: action.email,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  shareCluster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(shareCluster),
      switchMap(({ clusterId, names }) =>
        this.clustersApi.shareCluster(clusterId, names).pipe(
          map((response) =>
            response.failed.length !== 0
              ? shareClusterErr({
                  clusterId,
                  names: response.failed.map((it) => it.id),
                  error: extractError('Failed to invite some users'),
                })
              : shareClusterOK({
                  clusterId,
                  names,
                  notification: 'The invitation has been sent',
                }),
          ),
          catchError((err) =>
            of(
              shareClusterErr({
                error: extractError(err, 'Failed to invite user'),
                clusterId,
                names,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  confirmStopShareClusterWithTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stopShareWithClusterIntent),
      switchMap(({ clusterId, teamId }) =>
        combineLatest([
          this.store.select(clusterById(clusterId)).pipe(take(1)),
          this.store.select(team(teamId)).pipe(take(1)),
        ]).pipe(
          switchMap(([cluster, team]) =>
            !cluster || !team
              ? EMPTY
              : this.confirm
                  .confirm({
                    title: `Stop sharing with ${
                      team.type === TeamType.personal ? 'personal clusters' : `the “${team.name}” team`
                    }?`,
                    message: outdent`
          The team will no longer have access to ${
            team.type === TeamType.personal ? 'personal clusters' : `the cluster and the associated data`
          }.
        `,
                    dismissButtonLabel: `Cancel`,
                    confirmButtonLabel: `Stop`,
                  })
                  .pipe(switchMap((value) => (value ? of(removeClusterFromTeam({ teamId, clusterId })) : EMPTY))),
          ),
        ),
      ),
    ),
  );

  removeClusterFromTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeClusterFromTeam),
      withLatestFrom(this.store.select(clusterList), this.store.select(currentClusterInfo), this.store.select(teams)),
      mergeMap(([action, clusters, currentCluster, teams]) =>
        this.clustersApi.removeClusterFromTeam(action.clusterId, action.teamId).pipe(
          map((): string => {
            const team = getTeamById(teams ?? [], action.teamId);
            const clusterToRemove = clusters.find((c) => c.id === action.clusterId);

            if (!clusterToRemove || !team || !teams || !currentCluster) {
              // Should never happen
              return `Cluster removed from the team`;
            }

            const currentClusterWillChange = clusterTeams(currentCluster, teams).length === 1;
            const nextCurrentClusterId = chooseCurrentCluster(
              currentCluster.id,
              clusters.filter((c) => c.id !== currentCluster.id),
            );
            const nextCurrentCluster = clusters.find((c) => c.id === nextCurrentClusterId);
            const teamName = team.type === TeamType.personal ? 'personal clusters' : `the team “${team.name}”`;
            const defaultMessage = `Cluster “${clusterName(clusterToRemove)}” removed from ${teamName}`;

            if (clusterToRemove.id === currentCluster.id && currentClusterWillChange) {
              return nextCurrentCluster
                ? `${defaultMessage}. Current cluster is now “${clusterName(nextCurrentCluster)}”.`
                : `${defaultMessage}. You have no more clusters connected.`;
            }
            return defaultMessage;
          }),
          map((notification) =>
            removeClusterFromTeamOK({
              clusterId: action.clusterId,
              teamId: action.teamId,
              notification,
            }),
          ),
          catchError((err) =>
            of(
              removeClusterFromTeamErr({
                error: extractError(err, `Failed to remove cluster`),
                teamId: action.teamId,
                clusterId: action.clusterId,
              }),
            ),
          ),
        ),
      ),
    ),
  );
}
