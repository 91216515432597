/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DIALOG_UPDATE_CREDIT_CARD_ID } from '@app/common/components/dialog-update-credit-card/routes';
import { Confirm } from '@app/common/modules/gmc-confirm/confirm.service';
import { isTruthy } from '@app/common/utils';
import { extractError } from '@app/common/utils/extract-error';
import { login } from '@app/core/ngrx/actions';
import { BillingService } from '@app/core/services/billing.service';
import { omitPaymentMethodInfo } from '@app/domain/billing';
import { handleLoading } from '@common/rxjs-operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StripeService } from 'ngx-stripe';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  billingMethods,
  getBillingMethods,
  getBillingMethodsErr,
  removeCard,
  removeCardErr,
  removeCardIntent,
  removeCardOK,
  updateBillingMethod,
  updateBillingMethodErr,
  updateBillingMethodOK,
} from './actions/billing-method.actions';
import { targetSelector } from './app.selectors';

@Injectable()
export class BillingMethodEffects {
  constructor(
    private actions$: Actions,
    private billingService: BillingService,
    private confirmService: Confirm,
    private stripeService: StripeService,
    private matDialog: MatDialog,
    private store: Store,
  ) {}

  loading$ = createEffect(() =>
    this.actions$.pipe(
      handleLoading([
        { start: updateBillingMethod, end: [updateBillingMethodOK, updateBillingMethodErr] },
        { start: removeCard, end: [removeCardOK, removeCardErr] },
      ]),
    ),
  );

  billingMethods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      withLatestFrom(this.store.select(targetSelector).pipe(filter(isTruthy))),
      switchMap(([, target]) => (target !== 'hosted' ? EMPTY : of(getBillingMethods()))),
    ),
  );

  getCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBillingMethods),
      switchMap(() =>
        this.billingService.getCards().pipe(
          map((cards) => billingMethods({ data: cards })),
          catchError((err) => of(getBillingMethodsErr({ error: extractError(err, 'Failed to get billing methods') }))),
        ),
      ),
    ),
  );

  confirmCardRemoval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeCardIntent),
      switchMap((a) =>
        this.confirmService
          .confirm({
            title: `Remove card?`,
            message: `After the card is removed, it won't be charged. You must provide a new billing method before the next payment.`,
            dismissButtonLabel: `Cancel`,
            confirmButtonLabel: `Remove`,
          })
          .pipe(switchMap((result) => (result ? of(removeCard({ cardId: a.cardId })) : EMPTY))),
      ),
    ),
  );

  removeCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeCard),
      switchMap((a) =>
        this.billingService.removeCard(a.cardId).pipe(
          map(() => removeCardOK({ cardId: a.cardId, notification: 'Billing method removed' })),
          catchError((err) =>
            of(removeCardErr({ error: extractError(err, 'Failed to remove billing method'), cardId: a.cardId })),
          ),
        ),
      ),
    ),
  );

  updateBillingMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBillingMethod),
      exhaustMap((action) =>
        this.billingService.getClientSecretKey().pipe(
          switchMap(({ clientSecret }) =>
            this.stripeService
              .confirmCardSetup(clientSecret, {
                payment_method: {
                  metadata: {
                    company: action.company,
                  },
                  card: action.getStripeCardElement(),
                  billing_details: {
                    address: {
                      country: action.country,
                      city: action.city,
                      postal_code: action.postalCode,
                      state: action.state,
                      line1: action.streetAddress,
                    },
                    name: action.cardholderName,
                  },
                },
              })
              .pipe(
                switchMap(({ error, setupIntent }) =>
                  !error
                    ? typeof setupIntent?.payment_method === 'string'
                      ? this.billingService.setDefaultBillingMethod(setupIntent.payment_method).pipe(
                          map(() => updateBillingMethodOK({ notification: 'Billing method updated' })),
                          catchError((err) =>
                            of(
                              updateBillingMethodErr({
                                error: extractError(err, 'Failed to set default billing method'),
                              }),
                            ),
                          ),
                        )
                      : of(updateBillingMethodOK({ notification: 'Billing method updated' }))
                    : of(
                        updateBillingMethodErr({
                          error: extractError(error.message, 'Failed to update billing method'),
                          details: omitPaymentMethodInfo(error),
                        }),
                      ),
                ),
              ),
          ),
          catchError((err) =>
            of(
              updateBillingMethodErr({
                error: extractError(err, 'Failed to obtain Stripe client secret key'),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  refreshPaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBillingMethodOK),
      switchMap(() =>
        this.billingService.getCards().pipe(
          map((cards) => billingMethods({ data: cards })),
          catchError((err) => of(getBillingMethodsErr({ error: extractError(err, 'Failed to get billing methods') }))),
        ),
      ),
    ),
  );

  closeUpdateBillingMethodDialogOnSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateBillingMethodOK),
        tap(() => this.matDialog.getDialogById(DIALOG_UPDATE_CREDIT_CARD_ID)?.close()),
      ),
    { dispatch: false },
  );
}
