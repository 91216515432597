/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { ErrorHandler, Injectable, inject } from '@angular/core';
import { ErrorSincService } from './error-sinc.service';

@Injectable({ providedIn: 'root' })
export class GmcErrorHandler extends ErrorHandler {
  private errorSinc = inject(ErrorSincService);
  handleError(error: any): void {
    this.errorSinc.injestError(error, { silent: true, source: 'angular-error-handler' });
    // Preserves default Angular error handling (loggin to console)
    super.handleError(error);
  }
}
