/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { clusterTag } from '@app/core/ngrx';
import { toggleNotificationStatus } from '@app/core/notification/ngrx';
import { AppState } from '@app/core/types';
import { getUrlRegExp } from '@common/constants/url-regexp';
import { AsyncInput, isTruthy } from '@common/utils';
import { Store } from '@ngrx/store';
import { Notification } from '@shared/types/notification';
import { BehaviorSubject, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { DetectEllipsisDirective } from '../../../common/directives/detect-ellipsis.directive';
import { DateAgoPipe } from '../../../common/pipes/date-ago.pipe';

@Component({
  selector: 'notification-message',
  templateUrl: 'notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DetectEllipsisDirective, MatTooltipModule, MatButtonModule, NgClass, AsyncPipe, DateAgoPipe],
})
export class NotificationMessageComponent implements OnDestroy, OnChanges {
  @Input() notification?: Notification;
  @AsyncInput() notification$ = new BehaviorSubject<Notification | undefined>(this.notification);

  @Input() showActions = false;
  @Output() closeNotification = new EventEmitter<void>();

  constructor(
    private router: Router,
    private store: Store<AppState>,
  ) {}

  clusterTag$ = this.notification$.pipe(
    filter(isTruthy),
    switchMap((notification) =>
      notification.clusterId ? this.store.select(clusterTag(notification.clusterId)) : of(null),
    ),
  );

  toggleNotification = (event: MouseEvent) => {
    event.stopPropagation();
    if (!this.notification) return;
    const { id, read } = this.notification;
    this.store.dispatch(toggleNotificationStatus({ id, read: !read }));
  };

  navigate = () => {
    if (!this.notification) return;
    if (getUrlRegExp(['http', 'https']).test(this.notification.targetUrl)) {
      window.open(this.notification?.targetUrl, '_blank');
    } else {
      this.router.navigateByUrl(this.notification.targetUrl);
    }
    this.closeNotification.emit();
    this.store.dispatch(toggleNotificationStatus({ id: this.notification.id, read: true }));
  };

  dismiss = (event: MouseEvent) => {
    event.stopPropagation();
    this.closeNotification.emit();
  };

  ngOnChanges(): void {}
  ngOnDestroy(): void {}
}
