/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AppState } from '@app/core/types';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { initUser, isAuthorized } from '../ngrx';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  canLoad(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.pipe(
      filter((state) => !state.loading.has(initUser.type)),
      take(1),
      select(isAuthorized),
      map((val) => val || this.router.parseUrl('')),
    );
  }
}
