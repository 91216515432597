<!--
   Copyright (C) GridGain Systems. All Rights Reserved.
   _________        _____ __________________        _____
   __  ____/___________(_)______  /__  ____/______ ____(_)_______
   _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
   / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
   \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
-->

<div class="notification-toast-container" gta="notification-toast-container">
  @for (notification of notificationsList$ | async; track trackById($index, notification)) {
    <notification-toast [notification]="notification" (closeToast)="onToastClose($event)"></notification-toast>
  }
</div>
