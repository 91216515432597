/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { isDarkTheme } from '@app/core/ngrx';
import { BuildInfo } from '@app/core/services/build-info';
import { AppState } from '@app/core/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProjectNameComponent } from '../../common/components/project-name/project-name.component';
import { ShowForTargetDirective } from '../../common/modules/ggcc-target/show-for-target.directive';

@Component({
  selector: 'app-sidebar-footer',
  templateUrl: './sidebar-footer.component.html',
  styleUrls: ['./sidebar-footer.component.scss'],
  standalone: true,
  imports: [ShowForTargetDirective, MatTooltipModule, NgTemplateOutlet, ProjectNameComponent, RouterLink, AsyncPipe],
})
export class SidebarFooterComponent {
  constructor(
    public buildInfo: BuildInfo,
    private store: Store<AppState>,
  ) {}
  year = new Date().getFullYear();
  isDarkTheme$: Observable<boolean> = this.store.select(isDarkTheme);

  @Input()
  wideMode!: boolean;

  @HostBinding('class')
  public get className() {
    return this.wideMode ? 'wide' : 'narrow';
  }
}
