/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { PageResetPasswordGuard } from './page-reset-password/page-reset-password.guard';

export const routes = [
  { path: 'signup', loadComponent: () => import('./page-signup/page-signup.component'), title: 'Sign up' },
  { path: 'signin', loadComponent: () => import('./page-signin/page-signin.component'), title: 'Sign in' },
  { path: 'confirmation', loadComponent: () => import('./page-confirmation/page-confirmation.component') },
  {
    path: 'forgot-password',
    title: 'Reset password',
    loadComponent: () => import('./page-forgot-password/page-forgot-password.component'),
  },
  {
    path: 'reset',
    title: 'Reset password',
    loadComponent: () => import('./page-reset-password/page-reset-password.component'),
    canActivate: [PageResetPasswordGuard],
  },
];
