/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, createUrlTreeFromSnapshot } from '@angular/router';
import { getAllRouteParams } from '@app/common/utils/router';
import { Clusters3xApiService } from '@app/core/services/clusters-3x.service';
import { ClusterId } from '@shared/types/cluster';
import { catchError, mapTo, of } from 'rxjs';

export const physicalTopologyExists: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const { contextActionClusterId } = getAllRouteParams<{ contextActionClusterId: ClusterId }>(route);
  const redirectTo = createUrlTreeFromSnapshot(route, ['..']);
  return !contextActionClusterId
    ? redirectTo
    : inject(Clusters3xApiService)
        .getPhysicalTopology(contextActionClusterId)
        .pipe(
          mapTo(true),
          catchError(() => of(redirectTo)),
        );
};
