/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { extractError } from '@app/common/utils/extract-error';
import { interceptActionTaskErrors } from '@app/common/utils/task-interceptor';
import { BillingService } from '@app/core/services/billing.service';
import { createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { getCustomerBillingErr } from './actions/billing.actions';

@Injectable()
export class BillingEffects {
  constructor(private billingService: BillingService) {}
  notifyAboutFailureToGetCustomerBilling$ = createEffect(() =>
    interceptActionTaskErrors(this.billingService, 'getCustomerBilling').pipe(
      map((err) =>
        getCustomerBillingErr({
          error: extractError(err, 'Failed to get customer billing info'),
        }),
      ),
    ),
  );
}
