/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebSocketConnection } from '@app/core/services/websocket-connection.service';
import { Notification, NotificationId } from '@shared/types/notification';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationsApiService {
  constructor(
    private http: HttpClient,
    private ws: WebSocketConnection,
  ) {}

  subscribeToNotifications(): Observable<Notification[]> {
    return this.ws.getTopic<Notification[]>(`/user/topic/notifications`);
  }

  removeNotification(id: NotificationId): Observable<void> {
    return this.http.delete<void>(`/api/v1/notifications/${id}`);
  }

  toggleNotificationStatus(id: NotificationId, read: boolean): Observable<void> {
    return this.http.patch<void>(`/api/v1/notifications/${id}`, { read });
  }

  readAllNotifications(): Observable<void> {
    return this.http.patch<void>(`/api/v1/notifications`, {});
  }
}
