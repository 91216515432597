<!--
   Copyright (C) GridGain Systems. All Rights Reserved.
   _________        _____ __________________        _____
   __  ____/___________(_)______  /__  ____/______ ____(_)_______
   _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
   / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
   \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
-->

<attach-cluster-button *showForTarget="'on-premise'">
  <ng-template attachClusterButtonTemplate let-routerLink$="routerLink$" let-disabled$="disabled$">
    <a
      mat-icon-button
      [routerLink]="routerLink$ | async"
      [disabled]="disabled$ | async"
      matTooltip="Add cluster"
      gta="header-attach-cluster-button"
    >
      <mat-icon>add</mat-icon>
    </a>
  </ng-template>
</attach-cluster-button>
<ng-container *showForTarget="'hosted'">
  <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Add cluster" gta="header-add-cluster-menu-trigger"
    ><mat-icon>add</mat-icon></button
  >
  <mat-menu #menu="matMenu">
    <create-nebula-cluster-button>
      <ng-template createNebulaClusterButtonTemplate let-routerLink$="routerLink$" let-disabled$="disabled$">
        <a
          mat-menu-item
          [routerLink]="routerLink$ | async"
          [disabled]="disabled$ | async"
          gta="header-menu-create-cluster-button"
        >
          Create Managed cluster
        </a>
      </ng-template>
    </create-nebula-cluster-button>
    <attach-cluster-button>
      <ng-template attachClusterButtonTemplate let-routerLink$="routerLink$" let-disabled$="disabled$">
        <a
          mat-menu-item
          [routerLink]="routerLink$ | async"
          [disabled]="disabled$ | async"
          gta="attach-cluster-button"
          gta="header-menu-attach-cluster-button"
        >
          Attach cluster
        </a>
      </ng-template>
    </attach-cluster-button>
  </mat-menu>
</ng-container>
