/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { StripeError } from '@stripe/stripe-js';
import { omit } from 'lodash-es';

export const omitPaymentMethodInfo = (error: StripeError) =>
  omit(error, ['payment_method', 'setup_intent.payment_method', 'setup_intent.last_setup_error.payment_method']);
