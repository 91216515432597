<!--
   Copyright (C) GridGain Systems. All Rights Reserved.
   _________        _____ __________________        _____
   __  ____/___________(_)______  /__  ____/______ ____(_)_______
   _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
   / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
   \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
-->
<div class="mat-mdc-dialog-container">
  <div mat-dialog-content>
    <div class="notification__top-bar">
      <div class="notification__top-bar__right" gta="notification__count">
        <span>Notifications: {{ notificationsCount$ | async }}</span>
      </div>
      <mat-slide-toggle
        color="primary"
        labelPosition="before"
        gta="notification__show-unread"
        [checked]="showOnlyUnread$ | async"
        (change)="toggleShowUnread()"
      >
        <span>Only show unread</span>
      </mat-slide-toggle>
    </div>
    <mat-divider></mat-divider>
    <div class="notification__content">
      @if (notificationsCount$ | async) {
        <div class="notification__container">
          @for (notification of notifications$ | async; track trackById($index, notification); let isFirst = $first) {
            @if (!isFirst) {
              <mat-divider></mat-divider>
            }
            <notification-message
              class="notification__item"
              gta="notification__item"
              [notification]="notification"
              (closeNotification)="onClose()"
              [@slideInOut]="{ value: true, params: { inDuration: 300, outDuration: 250 } }"
            ></notification-message>
          }
        </div>
      } @else {
        <div
          class="notification__empty-state"
          [@inOutAnimation]="{ value: true, params: { inDuration: 300, outDuration: 200 } }"
        >
          @if (showOnlyUnread$ | async) {
            <empty-state gta="notification__only-unread-empty-state">
              <mat-icon svgIcon="ggcc:search-alt" empty-state-illustration></mat-icon>
              <span empty-state-title>No unread notifications</span>
              <span empty-state-description>To see all notifications, disable "Only show Unread" </span>
            </empty-state>
          } @else {
            <empty-state gta="notification__all-notifications-empty-state">
              <mat-icon svgIcon="ggcc:search-alt" empty-state-illustration></mat-icon>
              <span empty-state-title>No notifications yet</span>
              <span empty-state-description>Stay tuned! Notifications will show up here</span>
            </empty-state>
          }
        </div>
      }
    </div>
    @if (notificationsCount$ | async) {
      <mat-divider></mat-divider>
      <div class="notification__bottom-bar">
        <button mat-button type="button" color="primary" (click)="markAll()" gta="notification__read-all-trigger"
          >Mark all as read</button
        >
      </div>
    }
  </div>
</div>
