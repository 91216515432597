/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { InjectionToken } from '@angular/core';
import { makeInitialState } from '@app/core/ngrx';
import {
  dismissAllNotifications,
  dismissNotification,
  notifications,
  toggleNotificationsFilter,
  toggleNotificationStatus,
  toggleNotificationStatusErr,
} from '@app/core/notification/ngrx/notifications.actions';
import { NotificationsState } from '@app/core/notification/types';
import { mergeReducer } from '@common/utils/merge-reducer';
import { Action, ActionReducer, ActionReducerMap, createReducer, on } from '@ngrx/store';
import { orderBy, unionBy } from 'lodash-es';
import { localStorageSync } from 'ngrx-store-localstorage';

export const notificationsListReducer = createReducer<NotificationsState['notifications']['list']>(
  [],
  on(notifications, (state, action) =>
    // To properly handle the corner case when multiple entries have the same value for createdAt field
    // we introduce here secondary ordering by ID field to always have the consistent order
    action.data ? orderBy(unionBy(action.data, state, 'id'), ['createdAt', 'id'], ['desc', 'asc']) : [],
  ),
  // Mark notification read state optimistically and roll it back in case of error
  on(toggleNotificationStatus, toggleNotificationStatusErr, (state, action) =>
    state.map((it) => (it.id === action.id ? { ...it, read: action.read } : it)),
  ),
);

export const newNotificationsReducer = createReducer<NotificationsState['notifications']['new']>(
  [],
  on(notifications, (state, action) => [...state, ...action.new]),
  on(dismissNotification, (state, action) => state.filter((it) => it !== action.id)),
  on(dismissAllNotifications, (state, action) => []),
);

export const showOnlyUnreadReducer = createReducer<NotificationsState['notifications']['showOnlyUnread']>(
  true,
  on(toggleNotificationsFilter, (state) => !state),
);

export function notificationsLocalStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['showOnlyUnread'],
    rehydrate: true,
    removeOnUndefined: true,
    mergeReducer,
  })(reducer);
}

export const notificationsReducers = {
  list: notificationsListReducer,
  new: newNotificationsReducer,
  showOnlyUnread: showOnlyUnreadReducer,
} as ActionReducerMap<NotificationsState['notifications'], Action>;

export const NOTIFICATIONS_REDUCERS = new InjectionToken<ActionReducerMap<NotificationsState, Action>>(
  'Notifications reducers',
);

export const makeNotificationsInitialState = (): NotificationsState => ({
  ...makeInitialState(),
  notifications: { list: [], new: [], showOnlyUnread: true },
});
