/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { GGCCLicenseApi } from '@app/admin/license/ggcc-license-api.service';
import { isTruthy } from '@app/common/utils';
import { ggccLicenseStatus, login, logout } from '@app/core/ngrx/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, filter, map, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import { targetSelector } from './app.selectors';

@Injectable()
export class LicenseStatusEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private licenseApi: GGCCLicenseApi,
  ) {}

  licenseStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      withLatestFrom(this.store.select(targetSelector).pipe(filter(isTruthy))),
      switchMap(([, target]) =>
        target === 'hosted'
          ? EMPTY
          : this.licenseApi.licenseStatus().pipe(
              map((licenseStatus) => ggccLicenseStatus({ payload: licenseStatus })),
              takeUntil(this.actions$.pipe(ofType(logout))),
            ),
      ),
    ),
  );
}
