/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Store } from '@ngrx/store';
import { partition } from 'lodash-es';
import { filter, firstValueFrom, map, take } from 'rxjs';
import { DIALOG_OUTLET_NAME } from './common/modules/gmc-dialog/constants';
import { isTruthy } from './common/utils';
import { routeIterator } from './common/utils/router';
import { targetSelector } from './core/ngrx';
import { getProjectName } from './domain/application';

@Injectable({ providedIn: 'root' })
export class GmcTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  private projectName$ = inject(Store)
    .select(targetSelector)
    .pipe(filter(isTruthy), map(getProjectName), takeUntilDestroyed());

  override async updateTitle(routerState: RouterStateSnapshot) {
    const routes = Array.from(routeIterator(routerState.root));
    const [dialogRoutes, mainRoutes] = partition(routes, (route) => route.outlet === DIALOG_OUTLET_NAME);
    const currentRouteTree = dialogRoutes.length ? dialogRoutes : mainRoutes;
    const title = currentRouteTree
      .map((route) => route.routeConfig?.title)
      .filter(isTruthy)
      .join(' / ');
    const projectName = await firstValueFrom(this.projectName$.pipe(take(1)));
    this.title.setTitle(title ? `${title} - ${projectName}` : projectName);
  }
}
