/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { signupSuccess } from '@app/core/ngrx';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';
import { ANALYTICS_QUERY_PARAMS } from './query-params';

@Injectable({ providedIn: 'root' })
export class AnalyticsEffects {
  constructor(
    private actions: Actions,
    private angularitics: Angulartics2GoogleGlobalSiteTag,
    private cookies: CookieService,
  ) {}

  reportRegistration$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(signupSuccess),
        tap(() => {
          this.angularitics.eventTrack('Registration', {
            category: 'NebulaCC',
            label: 'Portal',
            value: 0,
          });
        }),
      ),
    { dispatch: false },
  );

  clearUtmCookiesAfterSignup$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(signupSuccess),
        tap(() => {
          for (const param of ANALYTICS_QUERY_PARAMS) {
            this.cookies.delete(param, '/');
          }
        }),
      ),
    { dispatch: false },
  );
}
