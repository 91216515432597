/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { completedClusterAction } from '@app/common/rxjs-operators/completed-cluster-action';
import { ClusterActionService } from '@app/core/services';
import { ActionName } from '@shared/types/cluster-actions';

@Injectable({ providedIn: 'root' })
export class BaselineApi {
  constructor(private clusterActions: ClusterActionService) {}
  setClusterBaselineNodes(clusterId: string, consistentIds: string[]) {
    return this.clusterActions
      .dispatch(ActionName.BASELINE_SET_BASELINE_TOPOLOGY, { clusterId, argument: { consistentIds } })
      .pipe(completedClusterAction());
  }
  setClusterBaselineAutoAdjust(clusterId: string, autoAdjustEnabled: boolean) {
    return this.clusterActions
      .dispatch(ActionName.BASELINE_UPDATE_AUTO_ADJUST_ENABLED, { clusterId, argument: { autoAdjustEnabled } })
      .pipe(completedClusterAction());
  }
  setClusterAutoAdjustAwaitingTime(clusterId: string, awaitingTime: number) {
    return this.clusterActions
      .dispatch(ActionName.BASELINE_UPDATE_AUTO_ADJUST_AWAIT_TIME, { clusterId, argument: { awaitingTime } })
      .pipe(completedClusterAction());
  }
}
