/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { GmcError } from '@app/core/types';
import { createAction, props } from '@ngrx/store';
import { Notification, NotificationId } from '@shared/types/notification';

export const notifications = createAction(
  '[Notifications] Get notifications',
  props<{ data: Notification[] | null; new: NotificationId[] }>(),
);
export const toggleNotificationsFilter = createAction('[Notifications] Toggle notifications filter');
export const toggleNotificationStatus = createAction(
  '[Notifications] Toggle notification status',
  props<{ id: NotificationId; read: boolean }>(),
);
export const toggleNotificationStatusOk = createAction('[Notifications] Toggle notification status OK');
export const toggleNotificationStatusErr = createAction(
  '[Notifications] Toggle notification status ERR',
  props<{ id: NotificationId; read: boolean } & GmcError>(),
);
export const readAllNotifications = createAction('[Notifications] Read all notifications');
export const readAllNotificationsOK = createAction('[Billing] Read all notifications OK');
export const readAllNotificationsErr = createAction('[Billing] Read all notifications ERR', props<GmcError>());

export const dismissNotification = createAction(
  '[Notifications] Dismiss notification',
  props<{ id: NotificationId }>(),
);

export const dismissAllNotifications = createAction('[Notifications] Dismiss all notifications');
