/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { DOCUMENT } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule, Provider } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ActivatedRouteSnapshot,
  BaseRouteReuseStrategy,
  RouteReuseStrategy,
  RouterStateSnapshot,
  TitleStrategy,
} from '@angular/router';
import { AnalyticsModule } from '@app/core/analytics/analytics.module';

import { StripeKeyGuard } from '@app/core/guards/stripe-key.guard';
import {
  APP_REDUCERS,
  AuthEffects,
  ClusterEffects,
  DashboardEffects,
  NotificationEffects,
  WebSocketEffects,
  appReducersMap,
  connectionToken,
  getUserFailure,
  initUser,
  login,
  makeInitialState,
  metaReducers,
  theme,
} from '@app/core/ngrx';
import { BaselineEffects } from '@app/core/ngrx/baseline.effects';
import { BillingMethodEffects } from '@app/core/ngrx/billing-method.effects';
import { BillingEffects } from '@app/core/ngrx/billing.effects';
import { CacheEffects } from '@app/core/ngrx/cache.effects';
import { Clusters3xEffects } from '@app/core/ngrx/clusters-3x.effects';
import { LicenseStatusEffects } from '@app/core/ngrx/license-status.effects';
import { NebulaPlansEffects } from '@app/core/ngrx/nebula-plans.effects';
import { TeamsEffects } from '@app/core/ngrx/teams.effects';
import { TutorialsEffects } from '@app/core/ngrx/tutorials.effects';
import { UtilEffects } from '@app/core/ngrx/util.effects';
import { RouteConnectionToken } from '@app/core/services/route-connection-token.service';

import { AppState, Themes } from '@app/core/types';

import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { datadogAppInitializer } from '@app/core/datadog.app-initializer';
import { provideDialogs } from '@common/modules/gmc-dialog/gmc-dialog.service';
import {
  CSS_VARS_HOST_TOKEN,
  CssVariablesService,
  DEFAULT_THEME_TOKEN,
  THEME_TOKEN,
} from '@common/modules/themes-switcher';
import { hoconLanguage } from '@common/monaco-configuration/hocon/language';
import { hoconLanguageConfiguration } from '@common/monaco-configuration/hocon/language-configuration';
import { conf, language } from '@common/monaco-configuration/ignite-sql/language';
import { Actions, EffectsModule, ofType } from '@ngrx/effects';
import {
  MinimalRouterStateSerializer,
  MinimalRouterStateSnapshot,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { Angulartics2Module } from 'angulartics2';
import type * as monaco from 'monaco-editor';
import { MonacoEditorModule, NGX_MONACO_EDITOR_CONFIG, NgxMonacoEditorConfig } from 'ngx-monaco-editor-v2';
import { NgxStripeModule } from 'ngx-stripe';
import { defer, lastValueFrom } from 'rxjs';
import { map, mapTo, take } from 'rxjs/operators';
import { extModules } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { authConfigAppInitializer } from './auth-config.app-initializer';
import { ClusterManagementEffects } from './cluster-management/ngrx/cluster-management.effects';
import { BannerComponent } from './core/banner/banner.component';
import { errorsAppInitializer } from './core/errors.app-initializer';
import { HeaderComponent } from './core/header/header.component';
import { NotificationModule } from './core/notification/notification.module';
import { GmcErrorHandler } from './core/services/error-handler.service';
import { SideNavComponent } from './core/side-nav/side-nav.component';
import { SidebarFooterComponent } from './core/sidebar-footer/sidebar-footer.component';
import { iconsAppInitializer } from './icons.app-initializer';
import { secureClusterSession3xInterceptor } from './secure-cluster-session-3x.interceptor';
import { secureClusterSessionAppInitializer } from './secure-cluster-session.app-initializer';
import { GmcTitleStrategy } from './title-strategy';

const THEME_SWITCHER_PROVIDER: Provider[] = [
  CssVariablesService,
  {
    provide: DEFAULT_THEME_TOKEN,
    useValue: Themes.LIGHT,
  },
  {
    provide: THEME_TOKEN,
    deps: [Store, DEFAULT_THEME_TOKEN],
    useFactory: (store: Store<AppState>, defaultValue: string) => {
      return store.select(theme).pipe(map((t) => (t ? t : defaultValue)));
    },
  },
  {
    provide: CSS_VARS_HOST_TOKEN,
    deps: [DOCUMENT],
    useFactory: (docRef: Document) => docRef.body,
  },
];

// workaround for https://github.com/ngrx/platform/issues/1781
export class CustomRouterStateSerializer extends MinimalRouterStateSerializer {
  serialize(routerState: RouterStateSnapshot): MinimalRouterStateSnapshot {
    let state = super.serialize(routerState);
    if (!state.url) state = { ...state, url: '/' };
    return state;
  }
}

// CustomRouteReuseStrategy is needed to prevent component rerendering in case if this component
// is used in multiple routes. Add {data: { reuse: true }} to the route config to make component reusable.
@Injectable({ providedIn: 'root' })
export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return super.shouldReuseRoute(future, curr) || future.data.reuse;
  }
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BannerComponent,
    SideNavComponent,
    SidebarFooterComponent,
    BrowserModule,
    AnalyticsModule,
    AppRoutingModule,
    MatToolbarModule,
    MatButtonModule,
    HeaderComponent,
    NotificationModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MonacoEditorModule.forRoot(),
    EffectsModule.forRoot([
      AuthEffects,
      NotificationEffects,
      WebSocketEffects,
      ClusterEffects,
      Clusters3xEffects,
      DashboardEffects,
      CacheEffects,
      UtilEffects,
      LicenseStatusEffects,
      TeamsEffects,
      NebulaPlansEffects,
      BillingMethodEffects,
      BillingEffects,
      ClusterManagementEffects,
      TutorialsEffects,
      BaselineEffects,
    ]),
    StoreModule.forRoot(APP_REDUCERS, {
      initialState: makeInitialState(),
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouterStateSerializer,
    }),
    extModules,
    Angulartics2Module.forRoot(),
    NgxStripeModule.forRoot(),
  ],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { disableTooltipInteractivity: true } as MatTooltipDefaultOptions,
    },
    provideDialogs(),
    { provide: APP_REDUCERS, useValue: appReducersMap },
    iconsAppInitializer,
    authConfigAppInitializer,
    secureClusterSessionAppInitializer,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (store: Store<AppState>, actions$: Actions, routeConnectionToken: RouteConnectionToken) => () =>
        lastValueFrom(
          defer(() => {
            const token = routeConnectionToken.value();
            if (token) store.dispatch(connectionToken({ connectionToken: token }));
            store.dispatch(initUser());
            return actions$.pipe(ofType(login, getUserFailure), take(1), mapTo(true));
          }),
        ),
      deps: [Store, Actions, RouteConnectionToken],
    },
    {
      provide: NGX_MONACO_EDITOR_CONFIG,
      useValue: {
        defaultOptions: {
          fontSize: 16,
          scrollBeyondLastLine: false,
          renderLineHighlight: 'none',
          scrollbar: {
            vertical: 'auto',
            verticalScrollbarSize: 8,
            verticalSliderSize: 8,
            horizontal: 'auto',
            horizontalScrollbarSize: 8,
            horizontalSliderSize: 8,
          },
        } as monaco.editor.IEditorConstructionOptions,
        onMonacoLoad() {
          window.monaco.languages.register({ id: 'ignite-sql' });
          window.monaco.languages.setMonarchTokensProvider('ignite-sql', language);
          window.monaco.languages.setLanguageConfiguration('ignite-sql', conf);

          window.monaco.languages.register({ id: 'hocon' });
          window.monaco.languages.setMonarchTokensProvider('hocon', hoconLanguage);
          window.monaco.languages.setLanguageConfiguration('hocon', hoconLanguageConfiguration);

          window.monaco.editor.defineTheme('cc-dark', {
            base: 'vs-dark',
            inherit: true,
            rules: [],
            colors: {
              'editor.background': '#030f11',
            },
          });
          window.monaco.editor.defineTheme('vs-gray', {
            base: 'vs',
            inherit: true,
            rules: [{ token: '', foreground: '000000', background: 'e9edf6' }],
            colors: {
              // https://microsoft.github.io/monaco-editor/playground.html#customizing-the-appearence-exposed-colors
              'editor.foreground': '#000000',
              'editor.background': '#e9edf6',
            },
          });
        },
      } as NgxMonacoEditorConfig,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    { provide: TitleStrategy, useClass: GmcTitleStrategy },
    StripeKeyGuard,
    ...THEME_SWITCHER_PROVIDER,
    errorsAppInitializer,
    datadogAppInitializer,
    {
      provide: ErrorHandler,
      useClass: GmcErrorHandler,
    },
    provideHttpClient(withInterceptors([secureClusterSession3xInterceptor])),
  ],
})
export class AppModule {}
