<!--
   Copyright (C) GridGain Systems. All Rights Reserved.
   _________        _____ __________________        _____
   __  ____/___________(_)______  /__  ____/______ ____(_)_______
   _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
   / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
   \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
-->

<button
  type="button"
  mat-icon-button
  class="notification-button"
  gta="notification-trigger-button"
  (click)="toggleNotificationOverlay()"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
>
  <mat-icon [dot]="hasUnreadNotifications$ | async" gta="notification-trigger-button__icon">notifications</mat-icon>
</button>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayPositions]="positions"
  (backdropClick)="toggleNotificationOverlay()"
  [cdkConnectedOverlayOpen]="(isOpen$ | async) ?? false"
>
  <notification-overlay (closeOverlay)="toggleNotificationOverlay()"></notification-overlay>
</ng-template>
