/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */
import { APP_INITIALIZER, inject, Provider } from '@angular/core';
import { targetSelector } from '@app/core/ngrx';
import { DatadogService } from '@app/core/services/datadog.service';
import { ErrorSincService } from '@app/core/services/error-sinc.service';
import { IS_PRODUCTION } from '@app/injection-tokens';
import { Store } from '@ngrx/store';

export const datadogAppInitializer: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: () => {
    const isProduction = inject(IS_PRODUCTION);
    const errorSinc = inject(ErrorSincService);
    const datadog = inject(DatadogService);
    const store = inject(Store);

    return async () => {
      const target = store.selectSignal(targetSelector)();
      if (isProduction && target === 'hosted') {
        errorSinc.subscribe().subscribe((error) => {
          datadog.onError({
            message: error.error.toString(),
            messageContext: { source: error.source, silent: error.silent },
            error: error.error,
          });
        });
        await datadog.initialize();
      }
    };
  },
};
