/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Component, HostBinding, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { identity } from 'lodash-es';

export type SideNavMenuItem =
  | {
      routerLink: string | string[];
      shortLabel: string;
      label: string;
      icon: string;
      disabled?: boolean;
      separator?: undefined;
    }
  | { separator: true; routerLink?: undefined };

export type SideNavMenu = SideNavMenuItem[];

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  standalone: true,
  imports: [
    MatListModule,
    MatButtonModule,
    RouterLinkActive,
    RouterLink,
    MatTooltipModule,
    MatIconModule,
    MatDividerModule,
  ],
})
export class SideNavComponent {
  @Input()
  navigationItems: SideNavMenu = [];

  @Input()
  @HostBinding('class.wide')
  wideMode!: boolean;

  // Silences the NG0956 warning (https://angular.dev/errors/NG0956)
  public identity = identity;
}
