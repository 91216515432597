/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { BillingState } from '@app/billing/page-billing/types';
import { isTruthy } from '@app/common/utils';
import { targetSelector } from '@app/core/ngrx';
import { nebulaSubscription } from '@app/core/ngrx/nebula-plans.selectors';
import { isSubscriptionUnpaid } from '@app/domain/nebula';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SubscriptionStatusGuard {
  constructor(
    private store: Store<BillingState>,
    private router: Router,
  ) {}

  canLoad(): true | Observable<boolean | UrlTree> {
    return this.canActivate();
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(targetSelector).pipe(
      filter(isTruthy),
      switchMap((target) => {
        if (target !== 'hosted') return of(true);

        return this.store.pipe(
          select(nebulaSubscription),
          filter(Boolean),
          take(1),
          map((subscription) => !isSubscriptionUnpaid(subscription) || this.router.parseUrl('')),
        );
      }),
    );
  }
}
