/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { isFormGroupInvalid } from '@app/common/utils/is-form-group-invalid';
import { Cluster2xConnectionToken, ClusterCredentials } from '@shared/types/cluster';
import { Cluster3xConnectionToken } from '@shared/types/cluster3x';
import { ScrollToFirstInvalidDirective } from '../../directives/scroll-to-first-invalid.directive';
import { FormClusterSigninComponent } from './form-cluster-signin/form-cluster-signin.component';

export interface DialogSecureClusterSignInData {
  connectionToken?: Cluster2xConnectionToken | Cluster3xConnectionToken;
  showConnectionTokenInput?: boolean;
  disallowColons?: boolean;
}

export type DialogSecureClusterSignInReturnValue = ClusterCredentials;

@Component({
  templateUrl: './dialog-secure-cluster-sign-in.component.html',
  styleUrls: ['./dialog-secure-cluster-sign-in.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatDialogModule, ReactiveFormsModule, ScrollToFirstInvalidDirective, FormClusterSigninComponent, MatButton],
})
export class DialogSecureClusterSignInComponent implements OnInit {
  private dialog: MatDialogRef<DialogSecureClusterSignInComponent, DialogSecureClusterSignInReturnValue> =
    inject(MatDialogRef);
  public data: DialogSecureClusterSignInData = inject(MAT_DIALOG_DATA);

  form = new FormGroup({ cluster: new FormGroup<any>({}) });
  ngOnInit() {
    this.form.controls.cluster.patchValue({ connectionToken: this.data.connectionToken });
  }
  submit() {
    this.form.markAsTouched();
    if (isFormGroupInvalid(this.form)) return;
    this.dialog.close({
      login: this.form.value.cluster.username,
      password: this.form.value.cluster.password,
      useAccessToken: this.form.value.cluster.useAccessToken,
    });
  }
}
