/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import {
  ComponentRef,
  ENVIRONMENT_INITIALIZER,
  EnvironmentInjector,
  Injectable,
  Injector,
  Provider,
  inject,
  reflectComponentType,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { getAllChildren, getRelativeToRoute } from '@app/common/utils/router';
import { Observable, buffer, filter, map, mergeAll } from 'rxjs';
import { DialogData } from './types';

type DialogRouteSnapshot = ActivatedRouteSnapshot & { data: DialogData };

const isDialogRoute = (snapshot: ActivatedRouteSnapshot): snapshot is DialogRouteSnapshot => {
  return 'dialogConfig' in snapshot.data;
};

// Copied from https://github.com/angular/angular/blob/daaed8943cfa4397baa53e34c4be3a56d36c4939/packages/router/src/utils/config.ts#L232
function getClosestRouteInjector(snapshot: ActivatedRouteSnapshot): EnvironmentInjector | null {
  if (!snapshot) return null;

  // If the current route has its own injector, which is created from the static providers on the
  // route itself, we should use that. Otherwise, we start at the parent since we do not want to
  // include the lazy loaded injector from this route.
  if ((snapshot.routeConfig as any)?._injector) {
    return (snapshot.routeConfig as any)._injector;
  }

  for (let s = snapshot.parent; s; s = s.parent) {
    const route = s.routeConfig;
    // Note that the order here is important. `_loadedInjector` stored on the route with
    // `loadChildren: () => NgModule` so it applies to child routes with priority. The `_injector`
    // is created from the static providers on that parent route, so it applies to the children as
    // well, but only if there is no lazy loaded NgModuleRef injector.
    if ((route as any)?._loadedInjector) return (route as any)._loadedInjector;
    if ((route as any)?._injector) return (route as any)._injector;
  }

  return null;
}

const isSameDialog = (ref: MatDialogRef<any, any>, snapshot: ActivatedRouteSnapshot | null) =>
  ref.componentInstance.constructor === snapshot?.component;

@Injectable()
export class GmcDialog {
  private router = inject(Router);
  private matDialog = inject(MatDialog);
  // The events are emitted from children to parents, but dialogs should be opened from parents to children,
  // otherwise the parent dialog will be displayed over child.
  private activationEndInReverseOrder$: Observable<ActivationEnd> = this.router.events.pipe(
    buffer(this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))),
    map((events) => events.reverse()),
    mergeAll(),
    filter((e): e is ActivationEnd => e instanceof ActivationEnd),
  );
  // Can't use MatLegacyDialog#openDialogs because the dialogs can be closed by navigations too
  private openDialogs = new Set<MatDialogRef<any>>();

  constructor() {
    this.activationEndInReverseOrder$.subscribe(({ snapshot }) => {
      const openedDialogSnapshots: DialogRouteSnapshot[] = getAllChildren(snapshot.root).filter(isDialogRoute);

      // Close dialogs no longer opened at routes
      Array.from(this.openDialogs.values()).forEach((ref) => {
        if (!openedDialogSnapshots.some((snapshot) => isSameDialog(ref, snapshot))) {
          this.matDialog.getDialogById(ref.id)?.close();
          this.openDialogs.delete(ref);
        }
      });

      const dialogComponent = snapshot.component;

      if (
        !isDialogRoute(snapshot) ||
        !dialogComponent ||
        Array.from(this.openDialogs.values()).some((ref) => isSameDialog(ref, snapshot))
      )
        return;

      const injector = Injector.create({
        parent: getClosestRouteInjector(snapshot) as Injector,
        providers: [
          {
            provide: ActivatedRoute,
            useFactory: () => getAllChildren(this.router.routerState.root).find((s) => s.component === dialogComponent),
          },
          {
            provide: ActivatedRouteSnapshot,
            useFactory: () => inject(ActivatedRoute).snapshot,
          },
        ],
      });
      const dialogRef = this.matDialog.open(dialogComponent, {
        // Assuming direct control 👾 over closing the dialogs.
        // By default, all dialogs are closed on navigation, but some have to remain open if a matching route is still active.
        closeOnNavigation: false,
        injector: injector ?? undefined,
        ...snapshot.data.dialogConfig,
      });
      const componentRef = dialogRef._containerInstance._portalOutlet.attachedRef;
      const mirror = reflectComponentType(dialogComponent);
      if (componentRef instanceof ComponentRef && mirror) {
        for (const { templateName } of mirror.inputs) {
          componentRef.setInput(templateName, injector.get(ActivatedRouteSnapshot).data[templateName]);
        }
      }
      this.openDialogs.add(dialogRef);
      dialogRef.afterClosed().subscribe(() => {
        // Already closed by navigation
        if (!this.openDialogs.has(dialogRef)) return;
        this.openDialogs.delete(dialogRef);
        // Don't interfere with current navigations, for example when a lazy module is still loading
        if (this.router.getCurrentNavigation()) return;
        // Trigger navigation if closing by means other than navigation
        if (snapshot.data.navigateSettings?.navigateAway) {
          snapshot.data.navigateSettings.navigateAway(this.router);
          return;
        }
        this.router.navigate(
          snapshot.data.navigateSettings?.commands || ['..'],
          snapshot.data.navigateSettings?.isRelativeToRoot
            ? undefined
            : {
                relativeTo: getRelativeToRoute(
                  this.router.routerState,
                  snapshot.data.navigateSettings?.relativeToLevelsBack,
                ),
              },
        );
      });
    });
  }
}

export const provideDialogs = (): Provider[] => [
  GmcDialog,
  {
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useValue() {
      inject(GmcDialog);
    },
  },
];
