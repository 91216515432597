/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { dismissNotification } from '@app/core/notification/ngrx';
import { newNotificationsList } from '@app/core/notification/ngrx/notifications.selectors';
import { NotificationToastCloseEvent, NotificationsState } from '@app/core/notification/types';
import { Store } from '@ngrx/store';
import { Notification } from '@shared/types/notification';
import { Observable } from 'rxjs';
import { NotificationToastComponent } from '../notification-toast/notification-toast.component';

@Component({
  selector: 'notification-toast-container',
  templateUrl: 'notification-toast-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NotificationToastComponent, AsyncPipe],
})
export class NotificationToastContainerComponent {
  constructor(private store: Store<NotificationsState>) {}

  notificationsList$: Observable<Notification[]> = this.store.select(newNotificationsList).pipe(takeUntilDestroyed());

  onToastClose = (data: NotificationToastCloseEvent) => {
    this.store.dispatch(dismissNotification({ id: data.id }));
  };

  trackById(index: number, item: Notification) {
    return item.id;
  }
}
