/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { AsyncPipe, CurrencyPipe, DatePipe, NgTemplateOutlet } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';
import {
  currentCluster,
  hasClusters,
  isAdminPageOpened,
  isCurrentClusterInactive,
  isCurrentClusterinStatus,
  isNebulaCluster,
  licenseStatus,
  targetSelector,
} from '@app/core/ngrx';
import { toggleBanner } from '@app/core/ngrx/actions/closed-banners.actions';
import { firstCard } from '@app/core/ngrx/billing-method.selectors';
import { isMetricsDisabledBannerVisible } from '@app/core/ngrx/cluster.selectors';
import {
  hasNebulaPlansClusters,
  nebulaClusterTerminationTime,
  subscriptionAvailableCredit,
  subscriptionBalanceExhaustionTime,
} from '@app/core/ngrx/nebula-plans.selectors';
import { userIsAdmin } from '@app/core/ngrx/user.selectors';
import { AppState } from '@app/core/types';
import { MY_CLUSTER_DASHBOARD_ID } from '@app/monitoring-dashboard/constants/dashboard-id';
import { shareReplayLastWithRefCount } from '@common/rxjs-operators/share-replay-last-with-ref-count';
import { Store } from '@ngrx/store';
import { centsToDollars, centsToNumber, dollarsToNumber } from '@shared/domain/currency';
import { ClusterStatus } from '@shared/types/cluster';
import { PriceInCents } from '@shared/types/currency';
import { GGCCLicenseType } from '@shared/types/license';
import { identity } from 'lodash-es';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';
import { MapPipe } from '../../common/pipes/map.pipe';
import { isOnMyClusterSelector } from '../ngrx/dashboard.selectors';

type BannerType =
  | 'on-premise-license-warning'
  | 'on-premise-license-with-no-clusters'
  | 'inactive-cluster'
  | 'nebula-subscription-unpaid'
  | 'nebula-trial-expired'
  | 'metrics-disabled'
  | 'nebula-trial-period'
  | 'nebula-cluster-limited'
  | null;

const getBannerType =
  <T>(type: BannerType) =>
  (source$: Observable<T>) =>
    source$.pipe(map((it) => (Boolean(it) ? type : null)));

const isOnPremiseBanner = (type: BannerType) => {
  switch (type) {
    case 'inactive-cluster':
    case 'on-premise-license-warning':
    case 'on-premise-license-with-no-clusters':
      return true;
    default:
      return false;
  }
};

const isNebulaBanner = (type: BannerType) => {
  switch (type) {
    case 'nebula-subscription-unpaid':
    case 'nebula-trial-expired':
    case 'inactive-cluster':
    case 'metrics-disabled':
    case 'nebula-trial-period':
    case 'nebula-cluster-limited':
      return true;
    default:
      return false;
  }
};

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  providers: [CurrencyPipe],
  standalone: true,
  imports: [
    MatExpansionModule,
    MatIconModule,
    NgTemplateOutlet,
    MatToolbarModule,
    MatButtonModule,
    RouterLink,
    AsyncPipe,
    DatePipe,
    MapPipe,
  ],
})
export class BannerComponent {
  @ViewChild(MatExpansionPanel) panel?: MatExpansionPanel;

  constructor(
    private store: Store<AppState>,
    private currencyPipe: CurrencyPipe,
  ) {}

  userIsAdmin$ = this.store.select(userIsAdmin);
  licenseStatus$ = this.store.select(licenseStatus);
  licenseError$ = this.licenseStatus$.pipe(
    map((status) => (status?.licenseType === GGCCLicenseType.noLicense ? null : status?.errorMessage)),
  );

  licenseWithNoClustersMessage$ = combineLatest([
    this.licenseStatus$,
    this.userIsAdmin$,
    this.store.select(isAdminPageOpened),
    this.store.select(hasClusters),
  ]).pipe(
    map(([status, userIsAdmin, isAdminPage, hasClusters]) =>
      status?.licenseType === GGCCLicenseType.commercial &&
      !status?.errorMessage &&
      isAdminPage &&
      userIsAdmin &&
      !hasClusters
        ? 'Get started: control and monitor your first cluster'
        : null,
    ),
    shareReplayLastWithRefCount,
  );

  nebulaUnpaidTerminationTime$ = this.store.select(nebulaClusterTerminationTime);
  isMetricsDisabledBannerVisible$ = this.store
    .select(isMetricsDisabledBannerVisible)
    .pipe(getBannerType('metrics-disabled'));

  private onPremiseLicenseStatusError$ = this.licenseError$.pipe(getBannerType('on-premise-license-warning'));
  private onPremiseLicenseWithNoClusters$ = this.licenseWithNoClustersMessage$.pipe(
    getBannerType('on-premise-license-with-no-clusters'),
  );

  nebulaTrialBalanceExhaustionTime$ = this.store.select(subscriptionBalanceExhaustionTime);
  availableCredit$ = this.store.select(subscriptionAvailableCredit);
  private billingCard$ = this.store
    .select(targetSelector)
    .pipe(switchMap((target) => (target === 'hosted' ? this.store.select(firstCard) : of(null))));

  private nebulaUnpaidTermination$ = combineLatest([
    this.store.select(hasNebulaPlansClusters),
    this.nebulaUnpaidTerminationTime$,
    this.billingCard$,
  ]);

  private isCurrentClusterInactive$ = this.store
    .select(isCurrentClusterInactive)
    .pipe(getBannerType('inactive-cluster'));

  private nebulaSubscriptionUnpaid$ = this.nebulaUnpaidTermination$.pipe(
    map(([hasBilledClusters, terminationTime, card]) => hasBilledClusters && terminationTime && card),
    getBannerType('nebula-subscription-unpaid'),
  );

  private nebulaTrialPeriodExpired$ = this.nebulaUnpaidTermination$.pipe(
    map(([hasBilledClusters, terminationTime, card]) => hasBilledClusters && terminationTime && !card),
    getBannerType('nebula-trial-expired'),
  );
  private nebulaTrialPeriod$ = combineLatest([
    this.nebulaTrialBalanceExhaustionTime$,
    this.availableCredit$,
    this.billingCard$,
  ]).pipe(
    map(
      ([exhaustionTime, availableCredit, card]) =>
        availableCredit && centsToNumber(availableCredit) > 0 && exhaustionTime && !card,
    ),
    getBannerType('nebula-trial-period'),
  );

  private isClusterLimitedBannerVisible$ = this.store
    .select(isCurrentClusterinStatus(ClusterStatus.LIMITED))
    .pipe(getBannerType('nebula-cluster-limited'));

  private isNebulaCluster$ = this.store.select(isNebulaCluster);

  public isActivateClusterButtonVisible$ = this.store
    .select(isOnMyClusterSelector)
    .pipe(map((isOnMyCluster) => !isOnMyCluster));

  // by order in combine latest we have order of banner importance in which they will be shown
  private availableBanners$ = combineLatest([
    this.onPremiseLicenseStatusError$,
    this.nebulaSubscriptionUnpaid$,
    this.nebulaTrialPeriodExpired$,
    this.isCurrentClusterInactive$,
    this.isMetricsDisabledBannerVisible$,
    this.nebulaTrialPeriod$,
    this.isClusterLimitedBannerVisible$,
    this.onPremiseLicenseWithNoClusters$,
  ]).pipe(map((banners) => banners.filter(Boolean)));

  public myClusterLink$ = this.store
    .select(currentCluster)
    .pipe(
      map((clusterId) =>
        !clusterId ? null : ['clusters', clusterId, 'monitoring-dashboard', MY_CLUSTER_DASHBOARD_ID],
      ),
    );

  banners$ = combineLatest([this.availableBanners$, this.isNebulaCluster$, this.store.select(targetSelector)]).pipe(
    map(([banners, isNebula, target]) =>
      banners.filter((type) => (target === 'hosted' ? isNebulaBanner(type) : isOnPremiseBanner(type))),
    ),
    map((banners) => (banners.length ? banners : null)),
  );

  formatPrice = (price: PriceInCents) => this.currencyPipe.transform(dollarsToNumber(centsToDollars(price)));

  getFilterIcon = (itemsCount: number) => {
    const postfix = itemsCount > 9 ? '9_plus' : `${itemsCount}`;
    return `filter_${postfix}`;
  };

  closePanel($event: MouseEvent) {
    if (!this.panel) return;
    this.panel.close();
    $event.stopPropagation();
  }

  closeBanner(id: string) {
    this.store.dispatch(toggleBanner({ id, opened: false }));
  }

  // Silences the NG0956 warning (https://angular.dev/errors/NG0956)
  public identity = identity;
}
