/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { extractError } from '@app/common/utils';
import { AuthService } from '@app/core/services';
import { AppState } from '@app/core/types';
import { Store } from '@ngrx/store';
import { ClusterInfo } from '@shared/types/cluster';
import { isString } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  clusterList,
  clustersLoaded,
  getUser,
  grantAdminSuccess,
  isAuthorized,
  navigateToSignupFailure,
} from '../ngrx';

@Injectable({ providedIn: 'root' })
export class AuthorizedUserGuard {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private srv: AuthService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(isAuthorized).pipe(
      switchMap((isAuthorized) => {
        if (!isAuthorized) return of(true);

        const loadedClusters$ = this.store.select(clustersLoaded).pipe(
          filter((loaded) => loaded),
          withLatestFrom(this.store.select(clusterList)),
          map(([, clusters]) => clusters),
        );

        const getClustersLink = (clusters: ClusterInfo[]) => {
          const firstClusterId = clusters[0]?.id;
          return isString(firstClusterId)
            ? this.router.parseUrl(`clusters/${firstClusterId}/monitoring-dashboard`)
            : this.router.parseUrl('clusters/list');
        };

        const [, token] = state.url.match(new RegExp(`.*\\/auth\\/signup\\?adminToken=(.*)`)) ?? [];
        if (token) {
          return this.srv.grantAdmin(token).pipe(
            tap(() => this.store.dispatch(grantAdminSuccess({ notification: "You've been granted admin rights" }))),
            catchError((error) => {
              this.store.dispatch(
                navigateToSignupFailure({
                  error: extractError(error, `Error granting admin rights`),
                }),
              );
              return of(void 0);
            }),
            tap(() => this.store.dispatch(getUser())),
            switchMap(() => loadedClusters$),
            map(getClustersLink),
          );
        }

        return loadedClusters$.pipe(map((clusters) => getClustersLink(clusters)));
      }),
    );
  }
}
