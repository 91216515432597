/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { inject, Injectable } from '@angular/core';
import { WebSocketConnection } from '@app/core/services/websocket-connection.service';
import { Cluster3xDistributionZone } from '@shared/types/distribution-zone';

@Injectable({ providedIn: 'root' })
export class DistributionZoneApiService {
  private ws = inject(WebSocketConnection);

  distributionZones(clusterId: string) {
    return this.ws.getTopic<Cluster3xDistributionZone[]>(`/topic/cluster/gg9/zones/${clusterId}`);
  }
}
