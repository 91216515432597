<!--
   Copyright (C) GridGain Systems. All Rights Reserved.
   _________        _____ __________________        _____
   __  ____/___________(_)______  /__  ____/______ ____(_)_______
   _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
   / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
   \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
-->

<mat-nav-list>
  @for (item of navigationItems; track identity(item)) {
    @if (item.routerLink) {
      <a
        mat-button
        class="sidenav-link"
        [routerLink]="item.routerLink"
        matTooltipPosition="right"
        [matTooltip]="item.label"
        matTooltipShowDelay="500"
        [matTooltipDisabled]="wideMode"
        [attr.aria-label]="item.label"
        routerLinkActive
        [disabled]="!!item.disabled"
        gta="sidenav-button"
        #rla="routerLinkActive"
      >
        <div class="icon-wrapper">
          <mat-icon [class.active]="rla.isActive" [svgIcon]="item.icon"></mat-icon>
          <span color="accent" class="item-label">{{ wideMode ? item.label : item.shortLabel }}</span>
        </div>
      </a>
    }
    @if (item.separator) {
      <mat-divider></mat-divider>
    }
  }
</mat-nav-list>
