/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { HttpErrorResponse, HttpEvent, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { buildToken } from '@common/utils/authToken';
import { HTTP_API_3X_AUTH_HEADER_NAME, HTTP_API_3X_UNAUTHORIZED_CODE } from '@shared/types/cluster3x';
import { Observable, catchError, throwError } from 'rxjs';
import { DialogSecureClusterSignInService } from './common/components/dialog-secure-cluster-sign-in/dialog-secure-cluster-sign-in.service';

const isGG9Route = (url: string) => url.startsWith('/api/v1/ignite3');

export const secureClusterSession3xInterceptor: HttpInterceptorFn = (req, next) => {
  const secureClusterSignInDialog = inject(DialogSecureClusterSignInService);

  if (!isGG9Route(req.url)) return next(req);

  const fn = (req: HttpRequest<unknown>): Observable<HttpEvent<unknown>> => {
    return next(req).pipe(
      catchError((res: HttpErrorResponse) => {
        if (res.status === HTTP_API_3X_UNAUTHORIZED_CODE) {
          return secureClusterSignInDialog.askForCredentials({
            retry: () => next(req),
            send: (credentials) => {
              const withCredentials = req.clone({
                setHeaders: {
                  [HTTP_API_3X_AUTH_HEADER_NAME]: buildToken(credentials.login!, credentials.password!),
                },
              });
              return fn(withCredentials);
            },
            error: () => throwError(() => res),
            dialogData: {
              disallowColons: true,
              connectionToken: 'foo',
              showConnectionTokenInput: false,
            },
          });
        }
        return throwError(() => res);
      }),
    );
  };

  return fn(req);
};
