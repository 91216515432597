/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AppState } from '@app/core/types';
import { Store } from '@ngrx/store';
import { isString } from 'lodash-es';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  clusterList,
  clustersLoaded,
  connectionToken,
  connectionTokenSelector,
  currentCluster,
  isAuthorized,
} from '../ngrx';

@Injectable({ providedIn: 'root' })
export class DefaultRouteGuard {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    const clusterData = this.store.select(clustersLoaded).pipe(
      filter((loaded) => loaded),
      switchMap(() => combineLatest([this.store.select(clusterList), this.store.select(currentCluster)])),
    );
    const toSignIn = of(this.router.parseUrl('auth/signin'));
    const toDefaultRoute = clusterData.pipe(
      map(([clusters, currentCluster]) => {
        const firstClusterId = clusters[0]?.id;
        return isString(firstClusterId) && clusters.length !== 0
          ? this.router.parseUrl(`clusters/${currentCluster ?? firstClusterId}/monitoring-dashboard`)
          : this.router.parseUrl('clusters/list');
      }),
    );
    const toResolveConnectionToken = clusterData.pipe(
      withLatestFrom(this.store.select(connectionTokenSelector)),
      switchMap(([[clusters, currentCluster], token]) => {
        if (!token) return toDefaultRoute;
        this.store.dispatch(connectionToken({}));
        const firstClusterId = clusters[0]?.id;
        return of(
          this.router.parseUrl(
            clusters.length === 0 && !isString(firstClusterId)
              ? `clusters/list(dialog:attach)?connectionToken=${token}`
              : `clusters/${
                  currentCluster ?? firstClusterId
                }/monitoring-dashboard(dialog:attach)?connectionToken=${token}`,
          ),
        );
      }),
    );

    return this.store
      .select(isAuthorized)
      .pipe(switchMap((isAuthorized) => (!isAuthorized ? toSignIn : toResolveConnectionToken)));
  }
}
