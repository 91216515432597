/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DIALOG_WIDTH_SM } from '@app/common/constants/dialog-width';
import { secondsToMilliseconds } from '@app/domain/time';
import { ClusterCredentials } from '@shared/types/cluster';
import { Observable, delay, switchMap } from 'rxjs';
import {
  DialogSecureClusterSignInComponent,
  DialogSecureClusterSignInData,
  DialogSecureClusterSignInReturnValue,
} from './dialog-secure-cluster-sign-in.component';

const RETRY_DELAY = secondsToMilliseconds(1);

@Injectable({ providedIn: 'root' })
export class DialogSecureClusterSignInService {
  private matDialog = inject(MatDialog);

  askForCredentials(params: {
    retry: () => Observable<any>;
    send: (credentials: ClusterCredentials) => Observable<any>;
    error: () => Observable<any>;
    dialogData?: DialogSecureClusterSignInData;
  }) {
    const openedDialog = this.matDialog.openDialogs.find(
      (dialog) => dialog.componentInstance instanceof DialogSecureClusterSignInComponent,
    );
    if (openedDialog) {
      return openedDialog.afterClosed().pipe(
        delay(RETRY_DELAY),
        switchMap((credentials) => (!credentials ? params.error() : params.retry())),
      );
    } else {
      return this.matDialog
        .open<DialogSecureClusterSignInComponent, DialogSecureClusterSignInData, DialogSecureClusterSignInReturnValue>(
          DialogSecureClusterSignInComponent,
          {
            data: params.dialogData,
            width: DIALOG_WIDTH_SM,
          },
        )
        .afterClosed()
        .pipe(switchMap((credentials) => (!credentials ? params.error() : params.send(credentials))));
    }
  }
}
