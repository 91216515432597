/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNoLicenseGuard } from '@app/admin/license/page-user-no-license/page-no-license.guard';
import { physicalTopologyExists } from '@app/cluster-management/dialog-initialize-ignite-3-cluster/physical-topology-exists.guard';
import { DIALOG_CONTACT_US_ID } from '@app/core/dialog-contact-us/dialog-contact-us.component';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { AuthorizedUserGuard } from '@app/core/guards/authorized-user.guard';
import { ClusterIdGuard } from '@app/core/guards/cluster-id.guard';
import { DefaultRouteGuard } from '@app/core/guards/default-route.guard';
import { HostedTargetGuard } from '@app/core/guards/hosted-target.guard';
import { isContextActionCluster3x } from '@app/core/guards/is-context-action-cluster-3x.guard';
import { NoLicenseGuard } from '@app/core/guards/no-license.guard';
import { RegularNebulaUserGuard } from '@app/core/guards/regular-nebula-user.guard';
import { StripeKeyGuard } from '@app/core/guards/stripe-key.guard';
import { SubscriptionStatusGuard } from '@app/core/guards/subscription-status.guard';
import { DIALOG_WIDTH_L, DIALOG_WIDTH_M } from '@common/constants/dialog-width';
import { DialogRoute, EmptyComponent } from '@common/modules/gmc-dialog';
import { DIALOG_OUTLET_NAME } from '@common/modules/gmc-dialog/constants';
import { ActiveTeamGuard } from './cluster-management/page-cluster-management/guards/active-team.guard';
import { clusterContextActionRoutes } from './cluster-management/routes';
import { routes as publicRoutes } from './public/routes';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [DefaultRouteGuard],
    component: EmptyComponent,
  },
  {
    path: 'password/reset',
    redirectTo: 'auth/reset',
  },
  {
    path: 'signin',
    redirectTo: 'auth/signin',
  },
  {
    path: 'auth',
    canActivate: [AuthorizedUserGuard],
    data: { hideSidebar: true },
    children: publicRoutes,
  },
  {
    path: 'terms',
    data: { hideSidebar: true },
    title: 'Terms and conditions',
    loadComponent: () => import('./page-terms/page-terms.component'),
  },
  {
    path: 'no-license',
    canActivate: [AuthGuard, PageNoLicenseGuard],
    data: { hideSidebar: true },
    loadComponent: () => import('./admin/license/page-user-no-license/page-user-no-license.component'),
  },
  {
    path: 'privacy-policy',
    data: { hideSidebar: true },
    title: 'Privacy policy',
    loadComponent: () => import('./page-policy/page-policy.component'),
  },
  {
    path: 'go/:token',
    redirectTo: '/', // triggers DefaultRouteGuard that redirects to proper route
  },
  {
    path: 'clusters',
    canActivate: [AuthGuard, NoLicenseGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        title: 'Cluster management',
        loadComponent: () => import('./cluster-management/page-cluster-management/page-cluster-management.component'),
        canActivate: [ActiveTeamGuard],
        children: clusterContextActionRoutes,
      },
      {
        path: ':clusterId',
        canActivate: [ClusterIdGuard],
        children: [
          {
            path: 'monitoring-dashboard',
            canLoad: [AuthGuard],
            title: 'Dashboard',
            loadChildren: () =>
              import('./monitoring-dashboard/monitoring-dashboard.module').then((m) => m.MonitoringDashboardModule),
          },
          {
            path: 'alerting',
            canLoad: [AuthGuard],
            title: 'Alerting',
            loadChildren: () => import('./alerting/alerting.module').then((m) => m.AlertingModule),
          },
          {
            path: 'tracing',
            canLoad: [AuthGuard],
            title: 'Tracing',
            loadChildren: () => import('./tracing/tracing.module').then((m) => m.TracingModule),
          },
          {
            path: 'sql',
            canLoad: [AuthGuard],
            title: 'Queries',
            loadChildren: () => import('./sql/sql.module').then((m) => m.SqlModule),
          },
          {
            path: 'snapshots',
            canLoad: [AuthGuard],
            canActivate: [RegularNebulaUserGuard],
            title: 'Snapshots',
            loadChildren: () => import('./snapshots/snapshots.module').then((m) => m.SnapshotsModule),
          },
          {
            path: 'caches',
            canLoad: [AuthGuard],
            title: 'Caches',
            loadChildren: () => import('./caches/caches.module').then((m) => m.CachesModule),
          },
          {
            path: 'code-deployment',
            canLoad: [AuthGuard],
            title: 'Code deployment',
            loadChildren: () => import('./code-deployment/code-deployment.module').then((m) => m.CodeDeploymentModule),
          },
          {
            path: 'compute',
            canLoad: [AuthGuard],
            title: 'Compute',
            loadChildren: () => import('./compute-grid/compute-grid.module').then((m) => m.ComputeGridModule),
          },
          {
            path: 'tables',
            canLoad: [AuthGuard],
            title: 'Tables',
            loadChildren: () => import('./tables/tables.module').then((m) => m.TablesModule),
          },
        ],
      },
    ],
  },
  {
    path: 'error-log',
    title: 'Error log',
    outlet: DIALOG_OUTLET_NAME,
    loadComponent: () => import('./core/dialog-error-log/dialog-error-log.component'),
    data: {
      dialogConfig: { width: DIALOG_WIDTH_L, autoFocus: false },
      navigateSettings: {
        commands: ['..', { outlets: { dialog: null } }],
        isRelativeToRoot: true,
      },
    },
  } as DialogRoute,
  {
    path: 'contact-form',
    title: 'Support',
    outlet: DIALOG_OUTLET_NAME,
    loadComponent: () => import('./core/dialog-contact-us/dialog-contact-us.component'),
    data: {
      dialogConfig: {
        width: DIALOG_WIDTH_M,
        id: DIALOG_CONTACT_US_ID,
        autofocus: false,
      },
      navigateSettings: {
        commands: ['..', { outlets: { dialog: null } }],
        isRelativeToRoot: true,
      },
    },
  },
  {
    path: 'attach',
    title: 'Attach cluster',
    outlet: DIALOG_OUTLET_NAME,
    canLoad: [AuthGuard],
    canActivate: [SubscriptionStatusGuard, NoLicenseGuard],
    loadChildren: () =>
      import('./cluster-management/dialog-attach-cluster/dialog-attach-cluster.module').then(
        (m) => m.DialogAttachClusterModule,
      ),
  },
  // Initialize cluster 3x
  {
    path: 'initialize',
    title: 'Initialize cluster',
    outlet: DIALOG_OUTLET_NAME,
    canLoad: [AuthGuard],
    canActivate: [isContextActionCluster3x, physicalTopologyExists],
    children: [
      {
        path: ':contextActionClusterId',
        loadComponent: () =>
          import(
            './cluster-management/dialog-initialize-ignite-3-cluster/dialog-initialize-ignite-3-cluster.component'
          ),
        data: {
          dialogConfig: {
            width: DIALOG_WIDTH_M,
            autoFocus: false,
            maxHeight: '800px',
          },
          navigateSettings: {
            commands: ['..', { outlets: { dialog: null } }],
            isRelativeToRoot: true,
          },
        },
      },
    ],
  },
  {
    path: 'provision',
    title: 'Create managed cluster',
    outlet: DIALOG_OUTLET_NAME,
    canLoad: [AuthGuard, HostedTargetGuard],
    canActivate: [StripeKeyGuard, NoLicenseGuard],
    loadChildren: async () => {
      // Pre-load the result dialog, otherwise it won't open due to a race in the dialog subsystem
      await import('./cluster-management/dialog-nebula-provision-result/dialog-nebula-provision-result.module');
      const { DialogProvisionClusterModule } = await import(
        './cluster-management/dialog-provision-cluster/dialog-provision-cluster.module'
      );
      return DialogProvisionClusterModule;
    },
  },
  {
    path: 'provision-result',
    title: 'Create managed cluster',
    outlet: DIALOG_OUTLET_NAME,
    canLoad: [AuthGuard, HostedTargetGuard],
    canActivate: [StripeKeyGuard],
    children: [
      {
        path: ':contextActionClusterId',
        loadChildren: () =>
          import('./cluster-management/dialog-nebula-provision-result/dialog-nebula-provision-result.module').then(
            (m) => m.DialogNebulaProvisionResultModule,
          ),
      },
    ],
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    title: 'User profile',
    loadChildren: () => import('./page-profile/page-profile.module').then((m) => m.PageProfileModule),
  },
  {
    path: 'admin',
    canLoad: [AuthGuard],
    title: 'Administration',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'teams',
    canActivate: [AuthGuard, NoLicenseGuard],
    title: 'Team management',
    loadChildren: () => import('./teams/teams.module').then((m) => m.TeamsModule),
  },
  {
    path: 'billing',
    canActivate: [AuthGuard],
    canLoad: [HostedTargetGuard],
    title: 'Billing',
    loadChildren: () => import('./billing/page-billing/page-billing.module').then((m) => m.PageBillingModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
