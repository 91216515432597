<!--
   Copyright (C) GridGain Systems. All Rights Reserved.
   _________        _____ __________________        _____
   __  ____/___________(_)______  /__  ____/______ ____(_)_______
   _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
   / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
   \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
-->

@if (formGroup) {
  <ng-container [formGroup]="formGroup">
    @if (showConnectionTokenInput) {
      <mat-form-field>
        <mat-label>Connection token</mat-label>
        <input
          type="text"
          name="connectionToken"
          matInput
          formControlName="connectionToken"
          autocomplete="off"
          cdkFocusInitial
          autofocus
          gta="connection-token-control-input"
        />
        <mat-error
          [gmc-errors]="formGroup.controls.connectionToken"
          gmc-errors-label="Connection token"
          gta="connection-token-control-error"
        ></mat-error>
      </mat-form-field>
    }
    @if (isSecure$ | async) {
      <mat-form-field>
        <mat-label>Cluster username</mat-label>
        <input
          type="text"
          autocomplete="off"
          matInput
          formControlName="username"
          autofocus
          autocomplete="new-password"
          gta="secure-cluster-username-input"
        />
        <mat-error
          [gmc-errors]="formGroup.controls.username"
          gmc-errors-label="Cluster username"
          gta="secure-cluster-username-error"
        ></mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Cluster password</mat-label>
        <input
          type="password"
          autocomplete="off"
          matInput
          formControlName="password"
          autocomplete="new-password"
          gta="secure-cluster-password-input"
          #clusterPassword
        />
        <password-visibility
          matSuffix
          [for]="clusterPassword"
          gta="secure-cluster-password-visibility-toggle"
        ></password-visibility>
        <mat-error
          [gmc-errors]="formGroup.controls.password"
          gmc-errors-label="Cluster password"
          gta="secure-cluster-password-error"
        ></mat-error>
      </mat-form-field>
      @if (showDeferToProviderToggle && (userOpenIdProvider$ | async); as provider) {
        <mat-slide-toggle formControlName="useAccessToken" color="primary" gta="secure-cluster-use-access-token-input"
          >Authenticate via “{{ provider | openIdProviderName }}”</mat-slide-toggle
        >
      }
    }
  </ng-container>
}
