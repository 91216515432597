/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialogContent } from '@angular/material/dialog';
import { NotificationToastCloseEvent } from '@app/core/notification/types';
import { Notification } from '@shared/types/notification';
import { NotificationMessageComponent } from '../notification-message/notification-message.component';

type NotificationToastAnimationState = 'default' | 'closing' | 'dismiss';
type ToastConfig = {
  closingTimeout: number;
  animation: {
    fadeIn: number;
    fadeOut: number;
    dismissFadeOut: number;
  };
};

@Component({
  selector: 'notification-toast',
  templateUrl: 'notification-toast.component.html',
  styleUrls: ['./notification-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('toastAnimation', [
      state('default', style({ opacity: 1 })),
      transition('void => *', [style({ opacity: 0, transform: 'translateX(25%)' }), animate('{{ fadeIn }}ms')]),
      transition('default => closing', animate('{{ fadeOut }}ms', style({ opacity: 0 }))),
      transition(
        'default => dismiss',
        animate('{{ dismissFadeOut }}ms', style({ opacity: 0, transform: `translateX(50%)` })),
      ),
    ]),
  ],
  standalone: true,
  imports: [MatDialogContent, NotificationMessageComponent],
})
export class NotificationToastComponent implements OnInit, OnDestroy {
  private intervalId?: ReturnType<typeof setTimeout>;

  animationState: NotificationToastAnimationState = 'default';

  @Input()
  config: ToastConfig = {
    closingTimeout: 7000,
    animation: {
      fadeIn: 300,
      fadeOut: 750,
      dismissFadeOut: 300,
    },
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
  ) {}

  @Input()
  notification!: Notification;

  @Output()
  closeToast = new EventEmitter<NotificationToastCloseEvent>();

  onAnimationFinished(event: AnimationEvent) {
    const currentState = event.toState as NotificationToastAnimationState;
    const isClosing = this.animationState === 'closing' || this.animationState === 'dismiss';
    const isClosed = currentState === 'closing' || currentState === 'dismiss';
    if (isClosing && isClosed) {
      this.closeToast.emit({
        id: this.notification.id,
      });
    }
  }

  onClose = () => {
    this.animationState = 'dismiss';
  };

  ngOnInit() {
    // Run setTimeout outside of Angular in order to avoid triggering change detection
    this.zone.runOutsideAngular(() => {
      this.intervalId = setTimeout(() => {
        this.animationState = 'closing';
        this.cdr.detectChanges();
      }, this.config.closingTimeout);
    });
  }

  ngOnDestroy() {
    if (!!this.intervalId) {
      clearTimeout(this.intervalId);
    }
  }
}
