/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ErrorSource } from '@app/core/services/error-sinc.service';
import { isNil } from 'lodash-es';
import { firstValueFrom } from 'rxjs';

type DatadogParams = { clientToken: string; service: string; env: string; version: string };

export type ErrorParams = { message: string; messageContext: { source: ErrorSource; silent: boolean }; error: any };

// same as Promise.withResolvers()
const makeDeferred = (): {
  promise: Promise<boolean> | undefined;
  resolve: (value: boolean) => void;
  reject: (error: any) => void;
} => {
  const deferred: any = {
    promise: undefined,
    resolve: () => void 0,
    reject: () => void 0,
  };
  deferred.promise = new Promise<boolean>((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });
  return deferred;
};

@Injectable({ providedIn: 'root' })
export class DatadogService {
  private http = inject(HttpClient);

  private initialized: boolean = false;

  isInitialized = makeDeferred();

  public async initialize() {
    if (!this.initialized) {
      try {
        const params = await firstValueFrom(this.http.get<DatadogParams>('assets/args.json'));
        if (!isNil(params) && !isNil(params.clientToken) && !isNil(params.service)) {
          const datadogLogs = await import('@datadog/browser-logs').then(({ datadogLogs }) => datadogLogs);
          datadogLogs.init({
            clientToken: params.clientToken,
            site: 'datadoghq.com',
            service: params.service,
            env: params.env,
            version: params.version,
            forwardConsoleLogs: [],
            sessionSampleRate: 100,
          });
          this.initialized = true;
        }
      } catch (err: any) {
        this.isInitialized.reject(err);
      }
    }
    this.isInitialized.resolve(this.initialized);
  }

  public async onError(params: ErrorParams) {
    const isInitialized = await this.isInitialized.promise;
    if (isInitialized) {
      const datadogLogs = await import('@datadog/browser-logs').then(({ datadogLogs }) => datadogLogs);
      datadogLogs.logger.error(params.message, params.messageContext, params.error);
    }
  }
}
