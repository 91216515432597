/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { handleLoading } from '@app/common/rxjs-operators';
import { extractError } from '@app/common/utils';
import { interceptActionTaskErrors } from '@app/common/utils/task-interceptor';
import { clusterList, currentClusterInfo } from '@app/core/ngrx/app.selectors';
import { Clusters3xApiService } from '@app/core/services/clusters-3x.service';
import { DistributionZoneApiService } from '@app/core/services/distribution-zone.service';
import { PartitionStateApiService } from '@app/core/services/partition-state.service';
import { TablesApiService } from '@app/core/services/tables.service';
import { AppState } from '@app/core/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isCluster3x } from '@shared/domain/cluster';
import { catchError, EMPTY, map, merge, of, switchMap, tap } from 'rxjs';
import {
  cluster3xDistributionZones,
  cluster3xDistributionZonesErr,
  cluster3xLocalPartitionStateErr,
  cluster3xLocalPartitionStates,
  cluster3xTables,
  cluster3xTablesErr,
  cluster3xTopology,
  initializeCluster,
  initializeClusterErr,
  initializeClusterOK,
  loadCluster3xDistributionZones,
  loadCluster3xLocalPartitionState,
  loadCluster3xTables,
  loadIgnite3PhysicalTopologyErr,
} from './actions/cluster-3x.actions';

@Injectable()
export class Clusters3xEffects {
  private actions$ = inject(Actions);
  private store = inject(Store<AppState>);
  private matDialog = inject(MatDialog);
  private clusters3xApi = inject(Clusters3xApiService);
  private tablesApi = inject(TablesApiService);
  private distributionZoneApi = inject(DistributionZoneApiService);
  private partitionStateApi = inject(PartitionStateApiService);

  loading$ = createEffect(() =>
    this.actions$.pipe(handleLoading([{ start: initializeCluster, end: [initializeClusterOK, initializeClusterErr] }])),
  );

  initializeClusterEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initializeCluster),
      switchMap((action) =>
        this.clusters3xApi.initialize(action.clusterId, action).pipe(
          map(() => initializeClusterOK({ notification: 'Cluster initialized' })),
          catchError((err) =>
            of(initializeClusterErr({ error: extractError(err, 'Failed to initialize the cluster') })),
          ),
        ),
      ),
    ),
  );

  closeInitializeDialogOnSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initializeClusterOK),
        tap(() => this.matDialog.closeAll()),
      ),
    { dispatch: false },
  );

  notifyAboutTopologyLoadError$ = createEffect(() =>
    interceptActionTaskErrors(this.clusters3xApi, 'getPhysicalTopology').pipe(
      map((err) =>
        loadIgnite3PhysicalTopologyErr({
          error: extractError(err, 'Failed to load cluster physical topology'),
        }),
      ),
    ),
  );

  clusterTopology$ = createEffect(() =>
    this.store.select(clusterList).pipe(
      // Can't use filter here because switchMap ensures we unsubscribe when there's no cluster ID
      switchMap((clusters) =>
        merge(
          ...clusters
            .filter((cluster) => isCluster3x(cluster))
            .map((cluster) =>
              this.clusters3xApi
                .clusterTopology(cluster.id)
                .pipe(map((data) => cluster3xTopology({ data, clusterId: cluster.id }))),
            ),
        ),
      ),
    ),
  );

  // Tables and Distribution zones

  loadTablesForCurrentCluster$ = createEffect(() =>
    this.store
      .select(currentClusterInfo)
      .pipe(
        switchMap((cluster) =>
          cluster && isCluster3x(cluster) ? [loadCluster3xTables({ clusterId: cluster.id })] : EMPTY,
        ),
      ),
  );

  loadCluster3xTables$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCluster3xTables),
      map((it) => it.clusterId),
      switchMap((clusterId) =>
        clusterId
          ? this.tablesApi.tables(clusterId).pipe(
              map((data) => cluster3xTables({ clusterId, data })),
              catchError((err) =>
                of(
                  cluster3xTablesErr({
                    error: extractError(err, `Failed to get tables list`),
                    clusterId,
                  }),
                ),
              ),
            )
          : EMPTY,
      ),
    ),
  );

  loadDistributionZonesForCurrentCluster$ = createEffect(() =>
    this.store
      .select(currentClusterInfo)
      .pipe(
        switchMap((cluster) =>
          cluster && isCluster3x(cluster) ? [loadCluster3xDistributionZones({ clusterId: cluster.id })] : EMPTY,
        ),
      ),
  );

  loadCluster3xDistributionZones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCluster3xDistributionZones),
      map((it) => it.clusterId),
      switchMap((clusterId) =>
        clusterId
          ? this.distributionZoneApi.distributionZones(clusterId).pipe(
              map((data) => cluster3xDistributionZones({ clusterId, data })),
              catchError((err) =>
                of(
                  cluster3xDistributionZonesErr({
                    error: extractError(err, `Failed to get distribution zones`),
                    clusterId,
                  }),
                ),
              ),
            )
          : EMPTY,
      ),
    ),
  );

  // Local and Global partitions

  loadLocalPartitionStatesForCurrentCluster$ = createEffect(() =>
    this.store
      .select(currentClusterInfo)
      .pipe(
        switchMap((cluster) =>
          cluster && isCluster3x(cluster) ? [loadCluster3xLocalPartitionState({ clusterId: cluster.id })] : EMPTY,
        ),
      ),
  );

  loadCluster3xLocalPartitionState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCluster3xLocalPartitionState),
      map((it) => it.clusterId),
      switchMap((clusterId) =>
        clusterId
          ? this.partitionStateApi.localPartitionStates(clusterId).pipe(
              map((data) => cluster3xLocalPartitionStates({ clusterId, data })),
              catchError((err) =>
                of(
                  cluster3xLocalPartitionStateErr({
                    error: extractError(err, `Failed to get local partition state`),
                    clusterId,
                  }),
                ),
              ),
            )
          : EMPTY,
      ),
    ),
  );
}
