/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { APP_INITIALIZER, Provider, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, switchMap, tap, withLatestFrom } from 'rxjs';
import { WINDOW } from '../injection-tokens';
import { errorSnackbarDurationSelector } from './ngrx/error.selectors';
import { GmcStompConnectionError, WebSocketConnection } from './services';
import { ErrorSincService } from './services/error-sinc.service';

enum KnownFrameHeaderMessages {
  CONNECTION_CLOSED = 'Connection to broker closed.',
  SESSION_CLOSED = 'Session closed.',
}

const isSilentStompError = (error: GmcStompConnectionError): boolean => {
  return (
    error?.frame?.headers?.message === KnownFrameHeaderMessages.CONNECTION_CLOSED ||
    error?.frame?.headers?.message === KnownFrameHeaderMessages.SESSION_CLOSED ||
    error.error.code === 'err.cluster-is-not-attached' ||
    error?.frame?.headers?.message === 'Subscription not found'
  );
};

export const errorsAppInitializer: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: () => {
    const errorSinc = inject(ErrorSincService);
    const actions = inject(Actions);
    const stomp = inject(WebSocketConnection);
    const store = inject(Store);
    const router = inject(Router);
    const snackbar = inject(MatSnackBar);
    const window = inject(WINDOW);

    return () => {
      actions.subscribe((action) => {
        if (action.error) {
          errorSinc.injestError(action.error, { silent: action.silent, source: 'effect' });
        }
      });

      stomp.errors$.subscribe((error) => {
        errorSinc.injestError(error, { silent: isSilentStompError(error), source: 'STOMP' });
      });

      window.addEventListener('error', (error) => {
        errorSinc.injestError(error, { silent: true, source: 'global' });
      });

      errorSinc
        .subscribe()
        .pipe(
          filter((error) => (error.source === 'STOMP' || error.source === 'effect') && !error.silent),
          withLatestFrom(store.select(errorSnackbarDurationSelector)),
          switchMap(([err, duration]) =>
            snackbar
              .open(err.error, `Show logs`, {
                duration,
                verticalPosition: 'top',
                panelClass: 'snackbar-error',
              })
              .onAction()
              .pipe(tap(() => router.navigate(['.', { outlets: { dialog: ['error-log'] } }]))),
          ),
        )
        .subscribe();
    };
  },
};
