/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import type * as monaco from 'monaco-editor';

type ILanguage = monaco.languages.IMonarchLanguage;

export const hoconLanguage: ILanguage = {
  defaultToken: '',
  tokenPostfix: '.conf',
  brackets: [
    { open: '{', close: '}', token: 'delimiter.bracket' },
    { open: '[', close: ']', token: 'delimiter.square' },
    { open: '(', close: ')', token: 'delimiter.parenthesis' },
  ],

  keywords: ['false', 'true'],
  operators: [':', '=', ','],

  symbols: /[\$%&\*\+@!\/\\\^~=\.:\-\?]/,
  idchars: /(?:\w|\-[a-zA-Z])*/,

  // escape sequences
  escapes: /\\(?:[btnfr\\"']|[0-7][0-7]?|[0-3][0-7]{2})/,

  tokenizer: {
    root: [
      { include: '@whitespace' },
      { include: '@comment' },

      // do not scan these as operators
      [/::?(?!@symbols)/, 'type.operator', '@type'],

      // delimiters and operators
      [/[{}()\[\]]/, '@brackets'],
      [/[;,.]/, 'delimiter'],
      [
        /@symbols/,
        {
          cases: {
            '@operators': 'keyword.operator',
            '@default': 'operator',
          },
        },
      ],

      // identifiers and keywords
      [
        /[a-z_$][\w$]*/,
        {
          cases: {
            '@keywords': 'keyword',
            '@default': 'identifier',
          },
        },
      ],

      // numbers
      [/\d*\.\d+([eE][\-+]?\d+)?/, 'number.float'],
      [/0[xX][0-9a-fA-F]+/, 'number.hex'],
      [/\d+/, 'number'],

      // strings
      [/"([^"\\]|\\.)*$/, 'string.invalid'], // non-teminated string
      [/"/, { token: 'string.quote', bracket: '@open', next: '@string' }],

      // characters
      [/'[^\\']'/, 'string'],
      [/(')(@escapes)(')/, ['string', 'string.escape', 'string']],
      [/'/, 'string.invalid'],
    ],

    whitespace: [
      [/[ \t\r\n]+/, 'white'],
      [/\/\*/, 'comment', '@comment'],
      [/\/\/.*$/, 'comment'],
    ],
    // Only line comments allowed
    comment: [[/#.*$/, 'comment']],

    string: [
      [/[^\\"]+/, 'string'],
      [/@escapes/, 'string.escape'],
      [/\\./, 'string.escape.invalid'],
      [/"/, { token: 'string.quote', bracket: '@close', next: '@pop' }],
    ],

    type: [[/[(\[<]/, { token: '@brackets.type' }, '@type_nested'], { include: '@type_content' }],
    type_nested: [
      [/[)\]>]/, { token: '@brackets.type' }, '@pop'],
      [/[(\[<]/, { token: '@brackets.type' }, '@push'],
      [/,/, 'delimiter.type'],
      [/([a-z]@idchars)(\s*)(:)(?!:)/, ['type.identifier.typeparam', 'white', 'type.operator']],
      { include: '@type_content' },
    ],
    type_content: [
      { include: '@whitespace' },
      [/_@idchars/, 'type.identifier.typevar'],
      [
        /[a-z]@idchars/,
        {
          cases: {
            '@keywords': { token: '@rematch', next: '@pop' },
            '@default': 'type.identifier',
          },
        },
      ],
      ['', '', '@pop'], // catch all
    ],
  },
};
