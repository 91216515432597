/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { extractError } from '@app/common/utils';
import { attachClusterError, clusterList, clustersLoaded, currentClusterChange } from '@app/core/ngrx';
import { Clusters } from '@app/core/services';
import { RouteConnectionToken } from '@app/core/services/route-connection-token.service';
import { AppState } from '@app/core/types';
import { chooseCurrentCluster } from '@app/domain/cluster';
import { Store } from '@ngrx/store';
import { ClusterInfo } from '@shared/types/cluster';
import { of } from 'rxjs';
import { catchError, filter, mapTo, switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ClusterIdGuard {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private clusters: Clusters,
    private connectionToken: RouteConnectionToken,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(clustersLoaded).pipe(
      filter((loaded) => loaded),
      switchMap(() => this.store.select(clusterList)),
      switchMap((list) => {
        const connectionToken = this.connectionToken.value();
        const userHasTheCluster = (list: ClusterInfo[]) => list.some((el) => el.id === route.params.clusterId);
        const goToTheClusterList = async () => {
          await this.router.navigate(['clusters', 'list'], {
            queryParams: { reload: 'true' },
            replaceUrl: true,
          });
          const currentCluster = chooseCurrentCluster(null, list);
          if (currentCluster) this.store.dispatch(currentClusterChange({ id: currentCluster }));
        };
        if (userHasTheCluster(list)) return of(true);
        if (!connectionToken) return goToTheClusterList();
        return this.clusters.attach(connectionToken).pipe(
          switchMap(() => this.store.select(clusterList).pipe(filter(userHasTheCluster), take(1))),
          mapTo(true),
          catchError((err) => {
            // GG-30348: Get rid of dispatch
            this.store.dispatch(
              attachClusterError({
                error: extractError(err, `Failed to attach cluster by token: ${this.connectionToken.value()}`),
              }),
            );
            // Forces location reload if already on /clusters/list
            return goToTheClusterList();
          }),
        );
      }),
    );
  }
}
