/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { routeContextActionClusterId } from '@app/cluster-management/ngrx/cluster-management.selectors';
import { Confirm } from '@app/common/modules/gmc-confirm/confirm.service';
import { handleLoading } from '@app/common/rxjs-operators/handle-loading';
import { isTruthy } from '@app/common/utils/assert';
import { extractError } from '@app/common/utils/extract-error';
import { getAllRouteParams, waitForNavigation } from '@app/common/utils/router';
import { interceptActionTaskErrors } from '@app/common/utils/task-interceptor';
import { clusters, currentClusterInfo, targetSelector, teamsExceptClusterTeamsThatIOwn } from '@app/core/ngrx';
import { getBillingMethods } from '@app/core/ngrx/actions/billing-method.actions';
import { Clusters } from '@app/core/services';
import { NebulaApiService } from '@app/core/services/nebula.service';
import { AppState } from '@app/core/types';
import { getPersonalTeam, getTeamById } from '@app/domain/teams';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction, routerNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { ClusterId, ClusterStatus } from '@shared/types/cluster';
import isEqual from 'lodash-es/isEqual';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  mergeMap,
  pairwise,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Dialog } from '../constants';
import {
  addClusterUser,
  addClusterUserErr,
  addClusterUserOk,
  changeClusterUserPassword,
  changeClusterUserPasswordErr,
  changeClusterUserPasswordOk,
  clusterUsers,
  deployNebulaCluster,
  deployNebulaClusterErr,
  deployNebulaClusterOk,
  deployNebulaClusterStarted,
  destroyNebulaCluster,
  destroyNebulaClusterErr,
  destroyNebulaClusterOk,
  getCloudProviderRegionsErr,
  getClusterUsers,
  getClusterUsersErr,
  loadNebulaAccountsListErr,
  loadNebulaClusterAccessListErr,
  loadNebulaPricesErr,
  removeClusterUser,
  removeClusterUserErr,
  removeClusterUserOk,
  renameCluster,
  renameClusterErr,
  renameClusterOk,
  resumeSuspendedNebulaCluster,
  resumeSuspendedNebulaClusterErr,
  resumeSuspendedNebulaClusterIntent,
  resumeSuspendedNebulaClusterOK,
  suspendNebulaCluster,
  suspendNebulaClusterErr,
  suspendNebulaClusterIntent,
  suspendNebulaClusterOK,
  updateNebulaClusterAccessList,
  updateNebulaClusterAccessListErr,
  updateNebulaClusterAccessListOk,
} from './cluster-management.actions';

@Injectable()
export class ClusterManagementEffects {
  constructor(
    private actions$: Actions,
    private matDialog: MatDialog,
    private router: Router,
    private store: Store<AppState>,
    private nebulaApi: NebulaApiService,
    private clustersApi: Clusters,
    private confirm: Confirm,
  ) {}

  loading$ = createEffect(() =>
    this.actions$.pipe(
      handleLoading([
        { start: renameCluster, end: [renameClusterOk, renameClusterErr] },
        { start: addClusterUser, end: [addClusterUserOk, addClusterUserErr] },
        { start: changeClusterUserPassword, end: [changeClusterUserPasswordOk, changeClusterUserPasswordErr] },
        { start: getClusterUsers, end: [clusterUsers, getClusterUsersErr] },
      ]),
    ),
  );

  loadBillingMethods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      map(
        ({
          payload: {
            routerState: { url },
          },
        }) => url.includes(`dialog:provision`) || url.includes('dialog:attach'),
      ),
      withLatestFrom(this.store.select(targetSelector).pipe(filter(isTruthy))),
      switchMap(([payload, target]) => (target === 'hosted' && isTruthy(payload) ? of(getBillingMethods()) : EMPTY)),
      distinctUntilChanged(),
    ),
  );
  nebulaPriceLoadingError$ = createEffect(() =>
    interceptActionTaskErrors(this.nebulaApi, 'getProducts').pipe(
      map((error) =>
        loadNebulaPricesErr({
          error: extractError(error, 'Failed to load prices'),
        }),
      ),
    ),
  );
  closeProvisionCLusterDialogIfNoData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadNebulaPricesErr, getCloudProviderRegionsErr),
        tap(async () => {
          // The dialog may be still loading, need to wait for it to finish first
          await waitForNavigation(this.router);
          this.matDialog.getDialogById(Dialog.PROVISION_CLUSTER_ID)?.close();
        }),
      ),
    { dispatch: false },
  );
  getCloudProviderRegionsError$ = createEffect(() =>
    interceptActionTaskErrors(this.nebulaApi, 'getCloudProviderRegions').pipe(
      map((error) =>
        getCloudProviderRegionsErr({
          error: extractError(error, 'Failed to load cloud provider regions'),
        }),
      ),
    ),
  );
  deployNebulaCluster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deployNebulaCluster),
      exhaustMap((action) =>
        this.nebulaApi.provisionCluster(action.args).pipe(
          map((res) => deployNebulaClusterStarted({ data: res })),
          catchError((err) =>
            of(
              deployNebulaClusterErr({
                error: extractError(err, 'Failed to start cluster'),
              }),
            ),
          ),
        ),
      ),
    ),
  );
  notifyAboutFailedProvisioning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clusters),
      pairwise(),
      withLatestFrom(this.actions$.pipe(ofType(routerNavigatedAction))),
      switchMap(
        ([
          [{ data: clustersBefore }, { data: clustersAfter }],
          {
            payload: {
              event: { urlAfterRedirects },
            },
          },
        ]) => {
          const provisioningClusters = clustersBefore
            .filter((cluster) => cluster?.status === ClusterStatus.PROVISIONING)
            .map(({ id }) => id);

          const failedClusters = clustersAfter
            .filter((cluster) => cluster.status === ClusterStatus.FAILED)
            .map(({ id }) => id);

          const transitionedClusters = provisioningClusters.filter((id) => failedClusters.includes(id));

          return transitionedClusters.length && !urlAfterRedirects.endsWith('provision-result')
            ? of(
                deployNebulaClusterErr({
                  error: extractError(
                    'Failed to create a cluster, please try again. You will not be billed for this. Contact our support team if the cluster fails to start again.',
                  ),
                }),
              )
            : EMPTY;
        },
      ),
    ),
  );
  notifyAboutSuccessfulProvisioning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clusters),
      pairwise(),
      withLatestFrom(this.actions$.pipe(ofType(routerNavigatedAction))),
      switchMap(
        ([
          [{ data: clustersBefore }, { data: clustersAfter }],
          {
            payload: {
              event: { urlAfterRedirects },
            },
          },
        ]) => {
          const provisioningClusters = clustersBefore
            .filter((cluster) => cluster?.status === ClusterStatus.PROVISIONING)
            .map(({ id }) => id);

          const provisionedClusters = clustersAfter
            .filter((cluster) => cluster.status === ClusterStatus.ACTIVE || cluster.status === ClusterStatus.LIMITED)
            .map(({ id }) => id);

          const transitionedClusters = provisioningClusters.filter((id) => provisionedClusters.includes(id));

          return transitionedClusters.length && !urlAfterRedirects.endsWith('provision-result')
            ? of(
                deployNebulaClusterOk({
                  clusterId: transitionedClusters[0] || '',
                }),
              )
            : EMPTY;
        },
      ),
    ),
  );
  closeProvisionClusterDialogOnSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deployNebulaClusterStarted),
        tap(() => this.matDialog.getDialogById(Dialog.PROVISION_CLUSTER_ID)?.close()),
      ),
    { dispatch: false },
  );

  destroyNebulaCluster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(destroyNebulaCluster),
      tap(() => {
        this.matDialog.getDialogById(Dialog.DESTROY_NEBULA_CLUSTER_ID)?.close();
        this.matDialog.getDialogById(Dialog.NEBULA_PROVISION_RESULT_ID)?.close();
      }),
      exhaustMap((action) =>
        this.nebulaApi.destroyCluster(action.clusterId).pipe(
          map(() => destroyNebulaClusterOk({ clusterId: action.clusterId })),
          catchError((err) =>
            of(
              destroyNebulaClusterErr({
                clusterId: action.clusterId,
                error: extractError(err, 'Failed to destroy cluster'),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  nebulaAccessListLoadingError$ = createEffect(() =>
    interceptActionTaskErrors(this.nebulaApi, 'getAccessList').pipe(
      map((error) =>
        loadNebulaClusterAccessListErr({
          error: extractError(error, 'Failed to load access list'),
        }),
      ),
    ),
  );
  nebulaAccountsListLoadingError$ = createEffect(() =>
    interceptActionTaskErrors(this.nebulaApi, 'getAccounts').pipe(
      map((error) =>
        loadNebulaAccountsListErr({
          error: extractError(error, 'Failed to load accounts list'),
        }),
      ),
    ),
  );
  closeEditAccessListDialogIfNoRules$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadNebulaClusterAccessListErr),
        tap(async () => {
          // The dialog may be still loading, need to wait for it to finish first
          await waitForNavigation(this.router);
          this.matDialog.getDialogById(Dialog.EDIT_ACCESS_LIST_ID)?.close();
        }),
      ),
    { dispatch: false },
  );

  updateNebulaClusterAccessList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNebulaClusterAccessList),
      withLatestFrom(this.store.select(routeContextActionClusterId)),
      switchMap(([action, clusterId]) =>
        clusterId
          ? this.nebulaApi.updateAccessList(clusterId, action.args).pipe(
              map(() =>
                updateNebulaClusterAccessListOk({ notification: 'Nebula cluster access list will be updated soon' }),
              ),
              catchError((err) =>
                of(
                  updateNebulaClusterAccessListErr({
                    clusterId,
                    error: extractError(err, 'Failed to update Nebula cluster access list'),
                  }),
                ),
              ),
            )
          : EMPTY,
      ),
    ),
  );

  redirectToProvisioningResultDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deployNebulaClusterStarted),
        tap(({ data: { clusterId } }) => {
          this.router.navigate(
            // "primary: [{}]" means "current route, reset matrix params".
            // Resetting matrix params is important for the "Cluster Management" screen
            // which keeps current team filter as a filter panel service matrix and relies
            // on team reset and automatic selection.
            this.router.routerState.snapshot.url.includes('clusters/list')
              ? [{ outlets: { primary: [{}], dialog: ['provision-result', clusterId] } }]
              : [{ outlets: { dialog: ['provision-result', clusterId] } }],
          );
        }),
      ),
    { dispatch: false },
  );

  renameCluster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(renameCluster),
      exhaustMap((action) =>
        this.clustersApi.changeTag(action.clusterId, action.tag).pipe(
          map(() => renameClusterOk({ clusterId: action.clusterId, tag: action.tag, notification: `Cluster renamed` })),
          catchError((err) =>
            of(
              renameClusterErr({
                clusterId: action.clusterId,
                tag: action.tag,
                error: extractError(err, 'Failed to rename cluster'),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  handleTeamContext$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        filter((a) => a.payload.routerState.url.includes('/clusters/list')),
        map((a) => ({
          url: a.payload.routerState.url,
          currentTeam: getAllRouteParams(a.payload.routerState.root).currentTeam,
        })),
        switchMap(({ url, currentTeam: teamId }) =>
          this.store.select(teamsExceptClusterTeamsThatIOwn).pipe(
            filter(Boolean),
            tap((teams) => {
              if (!teamId && teams.length > 1) {
                const teamToAdd = getPersonalTeam(teams)?.id;
                this.router.navigateByUrl(url.replace('clusters/list', `clusters/list;currentTeam=${teamToAdd}`));
                return;
              }
              if (teamId && (teams.length === 1 || !getTeamById(teams, teamId))) {
                this.router.navigateByUrl(url.replace(`clusters/list;currentTeam=${teamId}`, `clusters/list`));
                return;
              }
            }),
            takeUntil(
              this.actions$.pipe(
                ofType(routerNavigatedAction),
                filter((a) => !a.payload.routerState.url.includes('/clusters/list')),
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  closeRenameClusterDialogOnSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(renameClusterOk),
        tap(() => this.matDialog.getDialogById(Dialog.RENAME_CLUSTER_ID)?.close()),
      ),
    { dispatch: false },
  );

  needClusterUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      map(
        (a) =>
          [
            a.payload.routerState.url.includes(`/credentials`),
            getAllRouteParams<{ contextActionClusterId: ClusterId }>(a.payload.routerState.root),
          ] as const,
      ),
      distinctUntilChanged((a, b) => isEqual(a, b)),
      filter(([routeMatches, { contextActionClusterId }]) => !!routeMatches && !!contextActionClusterId),
      switchMap(([, { contextActionClusterId }]) =>
        contextActionClusterId ? of(getClusterUsers({ clusterId: contextActionClusterId })) : EMPTY,
      ),
    ),
  );

  getClusterUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getClusterUsers),
      exhaustMap((action) =>
        this.clustersApi.getUsers(action.clusterId).pipe(
          map((res) =>
            clusterUsers({
              clusterId: action.clusterId,
              users: res,
            }),
          ),
          catchError((err) =>
            of(
              getClusterUsersErr({
                clusterId: action.clusterId,
                error: extractError(err, 'Failed to get cluster users'),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  closeClusterUsersDialogIfFailedToLoad$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getClusterUsersErr),
        tap(async () => {
          // The dialog may be still loading, need to wait for it to finish first
          await waitForNavigation(this.router);
          this.matDialog.getDialogById(Dialog.MANAGE_CLUSTER_USERS_ID)?.close();
        }),
      ),
    { dispatch: false },
  );

  reloadUsersOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addClusterUserOk, removeClusterUserOk, changeClusterUserPasswordOk),
      map((action) => getClusterUsers({ clusterId: action.clusterId })),
    ),
  );

  addClusterUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addClusterUser),
      exhaustMap((action) =>
        this.clustersApi.addUser(action.clusterId, action.username, action.password).pipe(
          map(() => addClusterUserOk({ clusterId: action.clusterId, username: action.username })),
          catchError((err) =>
            of(
              addClusterUserErr({
                error: extractError(err, 'Failed to add cluster user'),
                clusterId: action.clusterId,
                username: action.username,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  closeAddClusterUserDialogOnSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addClusterUserOk),
        tap(() => this.matDialog.getDialogById(Dialog.ADD_CLUSTER_USER_ID)?.close()),
      ),
    { dispatch: false },
  );

  changeClusterUserPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeClusterUserPassword),
      switchMap((action) =>
        this.clustersApi.removeUser(action.clusterId, action.username).pipe(
          switchMap(() =>
            this.clustersApi.addUser(action.clusterId, action.username, action.password).pipe(
              map(() => changeClusterUserPasswordOk({ clusterId: action.clusterId, username: action.username })),
              catchError((err) =>
                of(
                  changeClusterUserPasswordErr({
                    error: extractError(err, 'Failed to change user password'),
                    clusterId: action.clusterId,
                    username: action.username,
                  }),
                ),
              ),
            ),
          ),
          catchError((err) =>
            of(
              changeClusterUserPasswordErr({
                error: extractError(err, 'Failed to change user password'),
                clusterId: action.clusterId,
                username: action.username,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  closeChangeClusterUserPasswordDialogOnSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changeClusterUserPasswordOk),
        tap(() => this.matDialog.getDialogById(Dialog.CHANGE_CLUSTER_USER_PASSWORD_ID)?.close()),
      ),
    { dispatch: false },
  );

  removeClusterUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeClusterUser),
      mergeMap((action) =>
        this.clustersApi.removeUser(action.clusterId, action.username).pipe(
          map(() => removeClusterUserOk({ clusterId: action.clusterId, username: action.username })),
          catchError((err) =>
            of(
              removeClusterUserErr({
                error: extractError(err, 'Failed to remove user'),
                clusterId: action.clusterId,
                username: action.username,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  // Suspend

  suspendClusterIntent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(suspendNebulaClusterIntent),
      withLatestFrom(this.store.select(currentClusterInfo)),
      switchMap(([{ clusterId }, clusterInfo]) =>
        this.confirm
          .confirm({
            title: 'Suspend cluster?',
            message: `The cluster will be stopped. ${
              clusterInfo?.nebula?.hasInMemoryDataRegions
                ? 'Persistent data will be saved, but In-memory data will be lost.'
                : 'Persistent data will not be lost.'
            } No fees are applied for suspended clusters.`,
            confirmButtonLabel: 'Suspend',
            dismissButtonLabel: 'Cancel',
          })
          .pipe(switchMap((result) => (result ? of(suspendNebulaCluster({ clusterId })) : EMPTY))),
      ),
    ),
  );

  suspendCluster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(suspendNebulaCluster),
      switchMap((action) =>
        this.clustersApi.toggleClusterSuspendedState(action.clusterId, true).pipe(
          map(() => suspendNebulaClusterOK({ clusterId: action.clusterId })),
          catchError((err) =>
            of(
              suspendNebulaClusterErr({
                clusterId: action.clusterId,
                error: extractError(err, 'Failed to suspend cluster'),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  // Resume cluster

  resumeClusterIntent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resumeSuspendedNebulaClusterIntent),
      switchMap((action) =>
        this.confirm
          .confirm({
            title: 'Resume cluster?',
            message: 'The cluster will be brought back online. The billing will be resumed.',
            confirmButtonLabel: 'Resume',
            dismissButtonLabel: 'Cancel',
          })
          .pipe(
            switchMap((result) => (result ? of(resumeSuspendedNebulaCluster({ clusterId: action.clusterId })) : EMPTY)),
          ),
      ),
    ),
  );

  resumeCluster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resumeSuspendedNebulaCluster),
      switchMap((action) =>
        this.clustersApi.toggleClusterSuspendedState(action.clusterId, false).pipe(
          map(() => resumeSuspendedNebulaClusterOK({ clusterId: action.clusterId })),
          catchError((err) =>
            of(
              resumeSuspendedNebulaClusterErr({
                clusterId: action.clusterId,
                error: extractError(err, 'Failed to resume cluster'),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  redirectToClusterManagementIfNoMoreClusters$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clusters),
        withLatestFrom(this.actions$.pipe(ofType(routerNavigatedAction))),
        filter(
          ([{ data: clusters }, action]) =>
            clusters.length === 0 && action.payload.event.url.includes('monitoring-dashboard'),
        ),
        tap(() => this.router.navigate(['clusters', 'list'])),
      ),
    { dispatch: false },
  );

  redirectToClusterManagementAfterDestroyingAClusterOnMyCluster$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(destroyNebulaCluster), // Destoying might take a while, redirect immediately
        withLatestFrom(this.actions$.pipe(ofType(routerNavigatedAction))),
        filter(([, action]) => {
          return action.payload.event.url.includes('monitoring-dashboard');
        }),
        tap(() => this.router.navigate(['clusters', 'list'])),
      ),
    { dispatch: false },
  );
}
