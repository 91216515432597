/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Inject, Injectable } from '@angular/core';
import { isTruthy } from '@app/common/utils';
import { targetSelector } from '@app/core/ngrx';
import { GA4_TRACKING_ID, GA_TRACKING_ID, IS_PRODUCTION } from '@app/injection-tokens';
import { Store } from '@ngrx/store';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { filter, firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AnalyticsGoogle {
  constructor(
    private angularitics: Angulartics2GoogleGlobalSiteTag,
    private store: Store,
    @Inject(GA_TRACKING_ID) private trackingId3: string,
    @Inject(GA4_TRACKING_ID) private trackingId4: string,
    @Inject(IS_PRODUCTION) private isProduction: boolean,
  ) {}
  loadGA() {
    const tag = document.createElement('script');
    tag.setAttribute('async', '');
    tag.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`);
    document.head.appendChild(tag);
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).gtag = function gtag() {
      (window as any).dataLayer.push(arguments);
    };
  }
  async init() {
    const target = await firstValueFrom(this.store.select(targetSelector).pipe(filter(isTruthy)));
    if (this.trackingId3 && target === 'hosted' && this.isProduction) {
      this.loadGA();
      if ((window as any).gtag) {
        (window as any).gtag('js', new Date());
        (window as any).gtag('config', this.trackingId3);
        (window as any).gtag('config', this.trackingId4);
      }
      this.angularitics.startTracking();
    }
  }
}
