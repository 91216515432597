/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { NgTemplateOutlet } from '@angular/common';
import { Component, computed, inject, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbar } from '@angular/material/toolbar';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import {
  clusterList,
  isAuthorized,
  isNonLdap,
  licenseStatus,
  logout,
  signupEnabled,
  targetSelector,
  theme,
  toggleTheme,
  user,
} from '@app/core/ngrx';
import { userIsAdmin } from '@app/core/ngrx/user.selectors';
import { dismissAllNotifications } from '@app/core/notification/ngrx';
import { BuildInfo } from '@app/core/services/build-info';
import { AppState } from '@app/core/types';
import { userFullNameOrEmail } from '@app/domain/user';
import { Store } from '@ngrx/store';
import { User } from '@shared/types/common';
import { GGCCLicenseType } from '@shared/types/license';
import { AvatarComponent } from '../../common/components/avatar/avatar.component';
import { ShowForTargetDirective } from '../../common/modules/ggcc-target/show-for-target.directive';
import { NotificationButtonComponent } from '../notification/notification-button/notification-button.component';
import { ButtonAddClusterComponent } from './button-add-cluster/button-add-cluster.component';
import { CurrentClusterSelectComponent } from './current-cluster-select/current-cluster-select.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    MatToolbar,
    MatButtonModule,
    MatTooltip,
    MatIcon,
    ShowForTargetDirective,
    RouterLink,
    NgTemplateOutlet,
    MatDivider,
    CurrentClusterSelectComponent,
    ButtonAddClusterComponent,
    NotificationButtonComponent,
    MatMenuModule,
    AvatarComponent,
  ],
})
export class HeaderComponent {
  private store = inject(Store<AppState>);
  private buildInfo = inject(BuildInfo);

  menuButtonClick = output();

  showMenuButton = input(false);

  theme = this.store.selectSignal(theme);
  private target = this.store.selectSignal(targetSelector);
  isAuthorized = this.store.selectSignal(isAuthorized);
  user = this.store.selectSignal(user);
  isAdmin = this.store.selectSignal(userIsAdmin);
  signupEnabled = this.store.selectSignal(signupEnabled);
  canOpenUserProfile = this.store.selectSignal(isNonLdap);
  hasClusters = computed(() => !!this.store.selectSignal(clusterList)()?.length);
  email = computed(() => this.user()?.email);
  showTrialWarning = computed(() => {
    return this.user() && this.store.selectSignal(licenseStatus)()?.licenseType === GGCCLicenseType.developer;
  });

  userFullNameOrEmail = (user: User) => userFullNameOrEmail(user);

  public releaseNotes = computed(() => {
    return this.target() === 'hosted'
      ? `https://www.gridgain.com/docs/nebula/release-notes/latest`
      : `https://www.gridgain.com/docs/control-center/latest/on-premise/release-notes/control-center/${this.buildInfo.productVersion}`;
  });
  public documentationLink = computed(() => {
    return this.target() === 'hosted'
      ? 'https://www.gridgain.com/docs/nebula/about/overview'
      : 'https://www.gridgain.com/docs/control-center/latest/overview';
  });

  toggleMode() {
    this.store.dispatch(toggleTheme());
  }

  logout() {
    this.store.dispatch(logout());
  }

  dismissNotifications() {
    this.store.dispatch(dismissAllNotifications());
  }
}
