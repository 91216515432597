/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { addBillingLoading, removeBillingLoading } from '@app/billing/page-billing/ngrx/billing.actions';
import { login } from '@app/core/ngrx/actions';
import { getNebulaPlans, getNebulaPlansErr, nebulaPlans } from '@app/core/ngrx/actions/nebula-plans.actions';
import { NebulaApiService } from '@app/core/services/nebula.service';
import { AppState } from '@app/core/types';
import { extractError, isTruthy } from '@common/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { targetSelector } from './app.selectors';

@Injectable()
export class NebulaPlansEffects {
  // handleLoading can't handle this properly because of debounce
  subscriptionsLoadingOn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getNebulaPlans),
      map((action) => addBillingLoading({ key: action.type })),
    ),
  );

  subscriptionsLoadingOff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(nebulaPlans, getNebulaPlansErr),
      map(() => removeBillingLoading({ key: getNebulaPlans.type })),
    ),
  );

  getNebulaSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      withLatestFrom(this.store.select(targetSelector).pipe(filter(isTruthy))),
      switchMap(([, target]) => (target !== 'hosted' ? EMPTY : of(getNebulaPlans()))),
    ),
  );

  getNebulaPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getNebulaPlans),
      switchMap(() =>
        this.nebulaService.getNebulaPlans().pipe(
          map((data) => nebulaPlans({ data })),
          catchError((err) => of(getNebulaPlansErr({ error: extractError(err, 'Failed to get nebula plans') }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private nebulaService: NebulaApiService,
    private store: Store<AppState>,
  ) {}
}
