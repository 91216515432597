/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogContent } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EmptyStateModule } from '@app/common/components/empty-state/empty-state.module';
import { readAllNotifications, toggleNotificationsFilter } from '@app/core/notification/ngrx';
import {
  notificationsListFiltered,
  notificationsShowOnlyUnread,
} from '@app/core/notification/ngrx/notifications.selectors';
import { NotificationsState } from '@app/core/notification/types';
import { inOutAnimation, slideInOutAnimation } from '@common/animations';
import { shareReplayLastWithRefCount } from '@common/rxjs-operators/share-replay-last-with-ref-count';
import { Store } from '@ngrx/store';
import { Notification } from '@shared/types/notification';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationMessageComponent } from '../notification-message/notification-message.component';

@Component({
  selector: 'notification-overlay',
  templateUrl: 'notification-overlay.component.html',
  styleUrls: ['./notification-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInOutAnimation, inOutAnimation],
  standalone: true,
  imports: [
    MatDialogContent,
    MatSlideToggleModule,
    MatDividerModule,
    NotificationMessageComponent,
    EmptyStateModule,
    MatIconModule,
    MatButtonModule,
    AsyncPipe,
  ],
})
export class NotificationOverlayComponent {
  constructor(private store: Store<NotificationsState>) {}

  @Output()
  closeOverlay = new EventEmitter<void>();

  showOnlyUnread$ = this.store.select(notificationsShowOnlyUnread);
  notifications$: Observable<Notification[]> = this.store
    .select(notificationsListFiltered)
    .pipe(shareReplayLastWithRefCount);
  notificationsCount$ = this.notifications$.pipe(map((n) => n.length));

  toggleShowUnread = () => this.store.dispatch(toggleNotificationsFilter());

  onClose = () => this.closeOverlay.emit();

  markAll = () => {
    this.store.dispatch(readAllNotifications());
  };

  trackById(index: number, item: Notification) {
    return item.id;
  }
}
