/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, createUrlTreeFromSnapshot } from '@angular/router';
import { isRouteContextActionCluster3x } from '@app/cluster-management/ngrx/cluster-management.selectors';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

export const isContextActionCluster3x: CanActivateFn = (route: ActivatedRouteSnapshot) =>
  inject(Store)
    .select(isRouteContextActionCluster3x)
    .pipe(map((is3x) => is3x || createUrlTreeFromSnapshot(route, ['..'])));
